import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './Router';


const root = ReactDOM.createRoot(
	document.getElementById('App')
);

root.render(<BrowserRouter><App/></BrowserRouter>);
