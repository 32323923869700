import { useEffect, useState } from "react";
import { menuServices } from "../Servicios/menu.service";
import { InvestorPortalApi } from "../Api/api.InvestorPortal";

const useYourInvestments = (props: any) => {
  const { userContactId } = props;
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [peips, setPeips] = useState<string[]>([]);
	const [modulesPermitted, setModulesPermitted] = useState<string[]>([]);
  const [institutions, setInstitutions] = useState<string[]>([]);
  const sidebarOptions = {
    plan: [
      {
        id: "PlanYourInvestment",
        text: "estimacionCashflows",
        url: "/PlanYourInvestment",
      },
      /* {
        id: 'otrosPlanifica',
        text: 'otros'
      } */
    ],
    analyze: [
      {
        id: "AnalyzeYourInvestment",
        text: "analisisCartera",
        url: "/AnalyzeYourInvestment",
      },
      /* {
        id: 'control',
        text: 'control'
      },
      {
        id: 'otrosAnaliza',
        text: 'otros'
      } */
    ],
  };  

  useEffect(() => {
    if (userContactId) getPeips();
    getInstitutions();
  }, [userContactId]);

  const getInstitutions = () => {
    InvestorPortalApi.getInstitutions().then((response: any) => {
      if (response.success) {
        setInstitutions(response.data);
      }
    });
  };


  const handleInvestmentOption = (option: any) => {
    setSelectedOption(option);
  };

  const getPeips = () => {
    new menuServices().getPeips(userContactId).then((data: any) => {
      let arrayFondons: any = [];
      data.map((x: any) => arrayFondons.push(x.peipNameShort));
      setPeips(arrayFondons);
    });
  };

  return {
    selectedOption,
    setSelectedOption,
    handleInvestmentOption,
    peips,
    setModulesPermitted,
    modulesPermitted,
    sidebarOptions,
    loading, 
    setLoading,
    institutions
  };
};

export default useYourInvestments;
