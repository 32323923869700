import { DoubleRightOutlined, LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import useMedia from "../../../hooks/useMedia";
import { Skeleton, Spin } from "antd";
import dayjs from "dayjs";

const RecentActivity = (props: any) => {
  const { translations, language, mainColor, viewReport } = props;
  const { loadingNews, recentActivityData, showFile } = useMedia({language});

  let linkTo: any = {
    newsletter: '/Media',
    capitalCalls: '/Documents?mode=investors',
    distribution: '/Documents?mode=investors',
    documents: '/Documents'
  }

  return (
    <div className="recent-activity">
      <h3>{translations.actividadReciente}</h3>
      {(loadingNews && recentActivityData.length === 0) ?
       <Skeleton className='skeleton-loading' style={{width: '100%'}} active /> 
       : 
       (recentActivityData.length > 0 &&
       <Spin
          size="large"
          spinning={loadingNews}
          indicator={<LoadingOutlined style={{ fontSize: 46, color: mainColor }} />}
        >
        {recentActivityData.map((item: any, index: number) => {
        return (
          <div key={index} className="recent-activity-item">
            <div className="recent-activity-header">
              <p>{translations[item.title]}</p>
              <Link to={linkTo[item.title]}>Ver más</Link>
            </div>
            <div className="recent-activity-item-container">
              {(item.data.length > 0) ? 
                item.data.map((data: any, index: number) => {
                if((item.title === 'capitalCalls' || item.title === 'distribution' || item.title === 'quarterReport' )){
                  if(index > 4){
                    return null;
                  }else{
                    let reportType = (item.title === 'capitalCalls') ? 'CapitalCalls' : 'Distribution';
                    return(
                      <button key={index} 
                        className="recent-activity-item-data" 
                        onClick={()=> item.title === 'quarterReport' ? showFile(data.file) : viewReport(reportType, dayjs(data.date, 'YYYY-MM-DD').format('YYYY/MM/DD'), [data.id])}
					  >
                        <p className="recent-activity-item-title">
                          <DoubleRightOutlined style={{ fontSize: 10, color: mainColor }}/> {data.name}
                        </p>
                        <p className="recent-activity-item-date">
                          {data.date}
                        </p>
                      </button>
                    )
                  }
                }
				else{
                  return (
                    <a href={data.file} key={index} className="recent-activity-item-data" target="_blank" rel="noreferrer">
                      <p className="recent-activity-item-title">
                        <DoubleRightOutlined style={{ fontSize: 10, color: mainColor }}/> {data.name}
                      </p>
                      <p className="recent-activity-item-date">
                        {data.date}
                      </p>
                    </a>
                  );
                }
              }) : 
              <p className="recent-activity-item-no-data">{translations.sinData}</p>}
            </div>
          </div>
        )
        })}
      </Spin>
       )}
    </div>
  );
};

export default RecentActivity;
