import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Format,
  Grid,
  Label,
  Legend,
  Series,
  ValueAxis,
} from "devextreme-react/chart";
import { formatNumber } from "../../../utils/Numbers";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const HomeFlows = (props: any) => {
  const { homeFlowsChart, translations, loading, mainColor } = props;
  return (
    <div className="flow-chart">
      <h4>{translations.estadoActual}</h4>
      <Spin
        size="large"
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 46, color: mainColor}} />}      
      >
      <Chart
        dataSource={homeFlowsChart}
        animation={{ enabled: true }}
        height={310}
        palette={["#dbdbdb"]}
        argumentAxis={{
          tick: { visible: false },
        }}
      >
        <CommonSeriesSettings
          argumentField="column"
          type="rangebar"
          minBarSize={2}
          barWidth={80}
        >
          <Label
            visible={true}
            backgroundColor="none"
            font={{ color: "black", size: "10px" }}
            customizeText={(e: any) => {
              if (e.originalValue === e.point.data.value1) {
                return "";
              } else if (e.valueText === "0.00") {
                return "€" + e.valueText + "m";
              } else {
                return (
                  "€" +formatNumber(e.point.data.value2 - e.point.data.value1)
                );
              }
            }}
          >
            <Format type="fixedPoint" precision={2} />
          </Label>
        </CommonSeriesSettings>
        <Series
          rangeValue1Field="value1"
          rangeValue2Field="value2"
          name="Value"
        />
        <Series
          type="stackedbar"
          valueField="rpi"
          color={mainColor}
        >
          <Label
            visible={true}
            backgroundColor="none"
            font={{ color: "black", size: "10px" }}
            customizeText={(e: any) => {
              return e.valueText === '0.00' ? '' : `€${formatNumber(e.value)}`;
            }}
          >
            <Format type="fixedPoint" precision={2} />
          </Label>
        </Series>
        <Series
          type="stackedbar"
          valueField="dpi"
          color="#6B6B6B"
        >
          <Label
            visible={true}
            backgroundColor="none"
            font={{ size: "11px", color: "#c3c3c3" }}
            customizeText={(e: any) => {
              return e.valueText === '0.00' ? '' : `€${formatNumber(e.value)}`;
            }}
          >
            <Format type="fixedPoint" precision={2} />
          </Label>
        </Series>
        <ArgumentAxis>
          <Label
            visible={true}
            font={{ color: "black", size: "11px" }}
            overlappingBehavior="hide"
            customizeText={(e: any) => translations[e.value]}
          />
        </ArgumentAxis>
        <Legend visible={false} />
        <ValueAxis>
          <Grid visible={true} />
        </ValueAxis>
      </Chart>
      </Spin>
      {(homeFlowsChart.length === 0 && !loading) && 
        <div className="no-data-found">
          {translations.noDataFound}
        </div>
      }
    </div>
  );
};

export default HomeFlows;
