import { Skeleton } from "antd";
import useMedia from "../../../hooks/useMedia";
import "./Media.scss";
import dayjs from "dayjs";

const Media = (props: any) => {
  const { translations, language } = props;
  const { videosData, newsData, newslettersData, maxLoad, onLoadMore } = useMedia({language});
  
  return (
    <div className="media-container">
      <h2>Media</h2>
      <section className="qualitas-tv">
        <h3>Qualitas TV</h3>
        <div className="videos-container">
          {videosData.map((item: any, index: number) => {
            return (
              <div key={index} className="video-item">
                <iframe
                  style={{ border: "none" }}
                  src={item.file}
                  width="280"
                  allowFullScreen
                  height="150"
                  title={item.name}
                ></iframe>
                <p>{item.name}</p>
              </div>
            );
          })}
        </div>
      </section>
      <section className="news">
        <h3>{translations.noticias}</h3>
        <div className="news-container">
          {newsData.map((item: any, index: number) => {
            if (index+1 > maxLoad) return null;
            return (
              <div key={index} className="news-item">
                <p className="news-category">{item.category} | {dayjs(item.date).format('DD/MM/YYYY')}</p>
                <p className="news-title">{item.title}</p>
                <div className="news-description" dangerouslySetInnerHTML={{__html: item.description.replaceAll('&nbsp;', '')}}/>
                <a className="readmode-btn" target="_blank" rel="noreferrer" href={item.url}>
                  {translations.leerMas}
                </a>
              </div>
            );
          })}
          {newsData.length === 0 && 
          [...Array(10)].map((_item, index) => {
            return (
              <Skeleton.Avatar 
                key={index} 
                active={true} 
                style={{width: 250, height: 210}} 
                shape='square' />
            );
          })}
        </div>
        <button onClick={onLoadMore} className="loadmore-btn">{translations.cargarMas}</button>
      </section>
      <section className="newsletter">
        <h3>Newsletters</h3>
        <div className="news-container">
          {newslettersData.map((item: any, index: number) => {
            return (
              <div key={index} className="newsletter-item">
                <a href={item.file} target="_blank" rel="noreferrer" className='newsletter-link'>
                  <p>{item.name}</p>
                  <p>{dayjs(item.date, 'DD/MM/YYYY').format('MM')}</p>
                </a>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default Media;
