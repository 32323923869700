const DocumentsIcon = () => {
  return (
    <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path className="cls-1" d="m27.1,5.4L21.79.1c-.06-.06-.15-.1-.23-.1H4.68c-1.03,0-1.87.84-1.87,1.87v26.25c0,1.03.84,1.87,1.87,1.87h20.64c1.03,0,1.87-.84,1.87-1.87V5.63c0-.09-.03-.17-.1-.23Zm-5.21-4.27l4.18,4.18h-4.18V1.13Zm3.43,28.21H4.68c-.67,0-1.21-.54-1.21-1.21V1.87c0-.67.54-1.21,1.21-1.21h16.55v4.97c0,.18.15.33.33.33h4.97v22.16c0,.67-.54,1.21-1.21,1.21Z"/>
      <path className="cls-1" d="m12.98,8.55h4.04c.18,0,.33-.15.33-.33s-.15-.33-.33-.33h-4.04c-.18,0-.33.15-.33.33s.15.33.33.33Z"/>
      <path className="cls-1" d="m23.13,10.98H6.87c-.18,0-.33.15-.33.33s.15.33.33.33h16.27c.18,0,.33-.15.33-.33s-.15-.33-.33-.33Z"/>
      <path className="cls-1" d="m23.13,14.06H6.87c-.18,0-.33.15-.33.33s.15.33.33.33h16.27c.18,0,.33-.15.33-.33s-.15-.33-.33-.33Z"/>
      <path className="cls-1" d="m23.13,17.15H6.87c-.18,0-.33.15-.33.33s.15.33.33.33h16.27c.18,0,.33-.15.33-.33s-.15-.33-.33-.33Z"/>
      <path className="cls-1" d="m22.52,22.25h-3.58c-.18,0-.33.15-.33.33s.15.33.33.33h3.58c.18,0,.33-.15.33-.33s-.15-.33-.33-.33Z"/>
      <path className="cls-1" d="m11.93,22.25h-1.59c-.61,0-1.1-.49-1.1-1.1,0-.18-.15-.33-.33-.33s-.33.15-.33.33c0,.61-.49,1.1-1.1,1.1-.18,0-.33.15-.33.33s.15.33.33.33c.59,0,1.11-.29,1.43-.74.32.45.84.74,1.43.74h1.59c.18,0,.33-.15.33-.33s-.15-.33-.33-.33Z"/>
    </svg>  
)
}

export default DocumentsIcon
