import { CheckCircleOutlined } from '@ant-design/icons';
import { Progress } from 'antd'
import React from 'react'

const Pagination = (props: any) => {
	  const { viewed, total, mainColor, onLoadMore, translations} = props;

	let percentage = (viewed / total) * 100; 
  return (
	<div className="pagination-container">
		<div className="pagination-component">
			<p>{translations.lookingAt} <strong> {viewed} {translations.outOf} {total} {translations.investors}</strong></p>
			<Progress percent={percentage} size="small" showInfo={false} strokeColor={mainColor}/>
			<button className="view-more-btn" onClick={onLoadMore} disabled={percentage === 100}>
				{percentage === 100 ? <CheckCircleOutlined style={{fontSize: 20}} /> : translations.viewMore}
			</button>
		</div>
	</div>
  )
}

export default Pagination