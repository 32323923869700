import "./CommonComponents.scss";

const Signatures = (props: any) => {
  const { translations, category } = props;

  return (
    <div className="signatures">
      {category === "scr" ? (
        <div className="signature">
          <img
            src="https://qualitasfunds.blob.core.windows.net/assets/image-gallery/signatures/signature2.png"
            alt="signature"
          />
          <p>
            <strong>Qualitas Equity Funds SGEIC S.A.</strong>
          </p>
          <p>{translations.representatedBy} Sergio García Huertas</p>
          <span>{translations.soleManager}</span>
        </div>
      ) : (
        <>
          <div className="signature">
            <img
              src="https://qualitasfunds.blob.core.windows.net/assets/image-gallery/signatures/signature2.png"
              alt="signature"
            />
            <p>
              <strong>Sergio García Huertas</strong>
            </p>
			<span>{translations.partner}</span>
          </div>
          <div className="signature">
            <img
              src="https://qualitasfunds.blob.core.windows.net/assets/image-gallery/signatures/signature1.png"
              alt="signature"
            />
            <p>
              <strong>Eric Halverson</strong>
            </p>
			<span>{translations.partner}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default Signatures;
