import React from "react";

const LanguageSelector = (props: any) => {
  const { language, setLanguage } = props;
  return (
    <div className="language-selector">
      <button className={`${language === 'es' && 'selectedLanguage'}`} onClick={()=> setLanguage('es')}>ES</button>
      <button className={`${language === 'en' && 'selectedLanguage'}`}onClick={()=> setLanguage('en')}>EN</button>
    </div>
  );
};

export default LanguageSelector;
