import { useEffect, useState } from "react";
import { InvestorPortalApi } from "../Api/api.InvestorPortal";
import dayjs from 'dayjs';
import { ChartData, InvestorDetail, Loading, PeipInvestor, SelectedDate, SelectedInstitution, SelectedInvestor, SelectedPeip, TvpiChart } from "../Modelos/Investors.model";
import { useLocation } from "react-router-dom";

const emptyData = {        
"commitQ1Value": 0,
"commitQ1Percentage": 0,
"commitQ2Value": 0,
"commitQ2Percentage": 0,
"commitVariation": 0.00,
"commitVariationPercentage": 0,
"drawdownQ1Value": 0,
"drawdownQ1Percentage": 0,
"drawdownQ2Value": 0,
"drawdownQ2Percentage": 0,
"drawdownVariation": 0,
"drawdownVariationPercentage": 0,
"distQ1Value": 0,
"distQ1Percentage": 0,
"distQ2Value": 0,
"distQ2Percentage": 0,
"distVariation": 0,
"distVariationPercentage": 0,
"pendQ1Value": 0,
"pendQ1Percentage": 0,
"pendQ2Value": 0,
"pendQ2Percentage": 0,
"pendVariation": 0,
"pendVariationPercentage": 0,
"commitFundsQ1Value": 0,
"commitFundsQ1Percentage": 0,
"commitFundsQ2Value": 0,
"commitFundsQ2Percentage": 0,
"commitFundsVariation": 0,
"commitFundsVariationPercentage": 0,
"drawdownFundsQ1Value": 0,
"drawdownFundsQ1Percentage": 0,
"drawdownFundsQ2Value": 0,
"drawdownFundsQ2Percentage": 0,
"drawdownFundsVariation": 0,
"drawdownFundsVariationPercentage": 0,
"distFundsQ1Value": 0,
"distFundsQ1Percentage": 0,
"distFundsQ2Value": 0,
"distFundsQ2Percentage": 0,
"distFundsVariation": 0,
"distFundsVariationPercentage": 0,
"portfolioQ1Value": 0,
"portfolioQ1Percentage": 0,
"portfolioQ2Value": 0,
"portfolioQ2Percentage": 0,
"portfolioVariation": 0,
"portfolioVariationPercentage": 0,
"netCashQ1Value": 0,
"netCashQ1Percentage": 0,
"netCashQ2Value": 0,
"netCashQ2Percentage": 0,
"netCashVariation": 0,
"netCashVariationPercentage": 0,
"totalNavQ1Value": 0,
"totalNavQ1Percentage": 0,
"totalNavQ2Value": 0,
"totalNavQ2Percentage": 0,
"totalNavVariation": 0,
"totalNavVariationPercentage": 0,
"grossMocQ1": 0,
"grossMocQ2": 0,
"grossMocVariation": 0,
"grossTirQ1": 0,
"grossTirQ2": 0,
"grossTirVariation": 0,
"grossTirVariationPercentage": 0,
"dpiQ1": 0,
"dpiQ2": 0,
"dpiVariation": 0,
"dpiVariationPercentage": 0,
"rpiQ1": 0,
"rpiQ2": 0,
"rpiVariation": 0,
"rpiVariationPercentage": 0,
"tvpiQ1": 0,
"tvpiQ2": 0,
"tvpiVariation": 0,
"tvpiVariationPercentage": 0
}

const useInvestmentsDetails = () => {
  const [loading, setLoading] = useState<Loading>({menu: false, table: false, chart: false});
  const [peipsList, setPeipsList] = useState<PeipInvestor[]>([]);
  const [investorList, setInvestorList] = useState<PeipInvestor[]>([]);
  const [filteredInvestorList, setFilteredInvestorList] = useState<PeipInvestor[]>([]);
  const [selectedPeip, setSelectedPeip] = useState<SelectedPeip>({idPeip: undefined, peip: undefined});
  const [selectedInstitution, setSelectedInstitution] = useState<SelectedInstitution>({idInstitution: undefined, institution: undefined});
  const [detailsBy, setDetailsBy] = useState<string>('funds');
  const [fundsData, setFundsData] = useState<any>(emptyData);
  const [investorVehicleData, setInvestorVehicleData] = useState<InvestorDetail[]>([]);
  const [investorFundData, setInvestorFundData] = useState<InvestorDetail[]>([]);
  const [selectedDates, setSelectedDates] = useState<SelectedDate>({
      from: '', 
      to: ''
  });
  const [selectedMode, setSelectedMode] = useState<string>('positionValue');
  const [vehiclesSelectedChart, setVehiclesSelectedChart] = useState<number[]>([]);
  const [cashflowChartData, setCashflowChartData] = useState<ChartData[]>([]);
  const [navChartData, setNavChartData] = useState<ChartData[]>([]);
  const [tvpiChartData, setTvpiChartData] = useState<TvpiChart[]>([]);
  const [selectedVehicleRadio, setSelectedVehicleRadio] = useState<number>(0);
  const [netAssetValueChart, setNetAssetValueChart] = useState<any[]>([]);
  const [quarterList, setQuarterList] = useState<any[]>([]);
  const [isTvpiModalOpen, setIsTvpiModalOpen] = useState<boolean>(false);
  const {search} = useLocation();

  useEffect(() => {
    getMenuItems()
    generateQuarterOptions()
    }, []);

  
  useEffect(() => {
    if(search === "?detailsBy=investors"){
      setDetailsBy('investors');
      setSelectedMode('movementDetails');
	  if(investorList.length > 0 && !selectedInstitution.idInstitution){
	  const firstInvestor = investorList[0];
      setSelectedInstitution({
        idInstitution: firstInvestor?.idInstitution || undefined,
        institution: firstInvestor?.investor || undefined
      });
	}
    }
  }, [search, investorList])
  
  useEffect(() => {
    if(detailsBy === 'funds'){
      if(!selectedPeip.idPeip) return;
      getFundFigures()
      getNavChartData()
      getTvpiChartData(selectedPeip.idPeip)
    }
    if(detailsBy === 'investors' && selectedInstitution.idInstitution){
      if(selectedMode === 'positionValue') getInvestorFundsData(selectedInstitution.idInstitution)
      if(selectedMode === 'movementDetails') getInvestorVehiclesData(selectedInstitution.idInstitution)
    }
  }, [selectedPeip.idPeip, selectedInstitution.idInstitution, selectedMode]);

  useEffect(() => {
    if(selectedDates.from && selectedDates.to){
      getFundFigures();
      getNavChartData()
    }
  }, [selectedDates]);

  useEffect(() => {
    if(selectedMode === 'movementDetails' && vehiclesSelectedChart.length !== 0){
      getCashflowChartData()
    }
  }, [vehiclesSelectedChart]);

  const handleFundClick = (id: number, type: string) => {
    if (type === 'investors') {
      setDetailsBy('investors');
      let instName = investorList.find((investor: PeipInvestor) => investor.idInstitution === id)?.investor;
      setSelectedInstitution({idInstitution: id, institution: instName});
      setSelectedPeip({idPeip: undefined, peip: undefined});
    }
    if(type === 'funds') {
      setDetailsBy('funds');
      let peipName = findFundName(peipsList, id)?.fund;
      setSelectedPeip({idPeip: id, peip: peipName});
      setSelectedInstitution({idInstitution: undefined, institution: undefined});
    }
  };

  const findFundName = (fundArray: PeipInvestor[], targetId: number) => {
    for (const category of fundArray) {
      for (const fund of category.funds) {
        if (fund.idFund === targetId) {
          return fund;
        }
      }
    }
    return null; 
  }
  
  const getMenuItems = async() => {
    setLoading({table: true, menu: true });
    await InvestorPortalApi.getMenuItems().then((response: any) => {
      if(response.success){
        let peipsData = response.data.filter((item: any) => item.type === 'funds');
        const investorsData = response.data.filter((item: any) => item.type === 'investors');
        
		/* FIX TEMPORAL QUITANDO FONDO VI Y DIRECT II */
		peipsData = peipsData.map((category: any) => ({
			...category,
			items: category.items.map((item: any) => ({
			  ...item,
			  funds: item.funds.filter((fund: any) => fund.idFund !== 16 && fund.idFund !== 17)
			}))
		  }));
		/* FIN DE FIX */
        setPeipsList(peipsData[0]?.items || []);
        setInvestorList(investorsData[0]?.items || []);
        setFilteredInvestorList(investorsData[0]?.items || []);
        
        if (peipsData.length > 0 && peipsData[0]?.items[0]?.funds[0]) {
          const { idFund, fund } = peipsData[0].items[0].funds[0];
          setSelectedPeip({ idPeip: idFund, peip: fund });
        }
      }
    })
    .finally(() => setLoading({ ...loading, menu: false }));
  }

  const filterInvestors = (value: string) => {
    const filteredInvestors = investorList.filter((investor: PeipInvestor) => investor.investor.toLowerCase().includes(value.toLowerCase()));
    setFilteredInvestorList(filteredInvestors);
  }

  const downloadExcel = (tableId: string) => {
    let datos = document.getElementById(tableId)
    let table = datos?.outerHTML;
    let uri = 'data:application/vnd.ms-excel;base64,';
    let template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" ><head><meta charset="UTF-8"><style>table, td, th {border: 1px solid black;}</style></head><body><table border="1">{table}</table></body></html>';
    let base64 = function (s: any) {
      return window.btoa(unescape(encodeURIComponent(s)));
    }
    let format = function (s: any, c: any) {
      return s.replace(/{(\w+)}/g, function (m: any, p: any) {
        return c[p];
      })
    }
    if (!table) return;
    let ctx = {
      worksheet: 'Worksheet',
      table: table
    }
    window.location.href = uri + base64(format(template, ctx));
  }

  const onChangeDates = (value: string, type: string) => {
      if (type === 'to' && value < selectedDates.from) return;
      if (type === 'from' && value > selectedDates.to) return;
      setSelectedDates({ ...selectedDates, [type]: value });
  }

  const getFundFigures = async() => {
    if(!selectedPeip.idPeip) return; 
    setLoading({ ...loading, table: true })
    let params = {
      peipId: selectedPeip.idPeip,
      date1: selectedDates.from,
      date2: selectedDates.to
    }
    await InvestorPortalApi.getFundFigures(params).then((response: any) => {
      setFundsData(response.data);
      setLoading({ ...loading, table: false })
    })
    .catch((error: any) => {
      console.log(error);
    })
    .finally(() => setLoading({ ...loading, table: false }));
  }

  const getInvestorVehiclesData = async(idInstitution: number) => {
    if(!selectedInstitution.institution) return;
    setInvestorVehicleData([]);
    setLoading({ ...loading, table: true })
    await InvestorPortalApi.getInvestorVehiclesData(idInstitution).then((response: any) => {
      if(response.success){
        let totals = {
          vehicle: 'Total',
          commitment: response.data.reduce((acc: any, item: any) => acc + item.commitment, 0),
          capitalCalls: response.data.reduce((acc: any, item: any) => acc + item.capitalCalls, 0),
          distributions: response.data.reduce((acc: any, item: any) => acc + item.distributions, 0),
          numShares: response.data.reduce((acc: any, item: any) => acc + item.numShares, 0),
          netAssetValue: response.data.reduce((acc: any, item: any) => acc + item.netAssetValue, 0),
          positionValue: response.data.reduce((acc: any, item: any) => acc + item.positionValue, 0),
          movements: []
        }
        setInvestorVehicleData([...response.data, totals]);
        setVehiclesSelectedChart(response.data.map((item: any) => item.idVehicle))
      }
      setLoading({ ...loading, table: false })
    })
    .catch((error: any) => {
      console.log(error);
    })
    .finally(() => setLoading({ ...loading, table: false }));
  }

  const getInvestorFundsData = async(idInstitution: number) => {
    if(!selectedInstitution.institution) return;
    setInvestorFundData([])
    setLoading({ ...loading, table: true })
    await InvestorPortalApi.getInvestorFundsData(idInstitution).then((response: any) => {
      if(response.success){
        let totals = {
          vehicleName: 'Total',
          commitment: response.data.reduce((acc: any, item: any) => acc + item.commitment, 0),
          capitalCalls: response.data.reduce((acc: any, item: any) => acc + item.capitalCalls, 0),
          distributions: response.data.reduce((acc: any, item: any) => acc + item.distributions, 0),
          numShares: response.data.reduce((acc: any, item: any) => acc + item.numShares, 0),
          netAssetValue: response.data.reduce((acc: any, item: any) => acc + item.netAssetValue, 0),
          positionValue: response.data.reduce((acc: any, item: any) => acc + item.positionValue, 0),
        }
        setInvestorFundData([...response.data, totals]);
        let firstVehicle = response.data[0]?.idVehicle
        if(firstVehicle){
		  setSelectedVehicleRadio(firstVehicle)
          getNetAssetValueData(firstVehicle, idInstitution)
        }
      }
      setLoading({ ...loading, table: false })
    })
    .catch((error: any) => {
      console.log(error);
    })
    .finally(() => setLoading({ ...loading, table: false }));
  }

  const getCashflowChartData = async() => {
    setLoading({ ...loading, chart: true })
    let params= {
      idInstitution: selectedInstitution.idInstitution,
      vehiclesSelectedChart
    }
    await InvestorPortalApi.getCashflowChart(params).then((res: any)=>{
			if(res.success){
        let cumulativeCount = 0;
        const dataArray = res.data.map((item: any) => {
          cumulativeCount += (item.value1 + item.value2);
          return {
            column: item.column,
            value1: item.value1,
            value2: item.value2,
            cumulativeCount: cumulativeCount,
          };
        });
        setCashflowChartData(dataArray)
      }else{
				console.log(res.errorMessage)
			}
		}).catch((e: any) => {
      console.log(e)
    }).finally(() => setLoading({ ...loading, chart: false }));
  }

  const getNavChartData = async() => {
    if(!selectedPeip.idPeip) return; 
    let params = {
      idPeip: selectedPeip.idPeip,
      firstQuarter: selectedDates.from,
      lastQuarter: selectedDates.to 
    }
    await InvestorPortalApi.getNavChartData(params).then((res: any)=>{
			if(res.success){
				setNavChartData(res.data)
			}else{
				console.log(res.errorMessage)
			}
		}).catch((e: any) => {
      console.log(e)
    })
  }

  const getTvpiChartData = async(idPeip: number) => {
    await InvestorPortalApi.getTVPIChartData(idPeip).then((res: any)=>{
			if(res.success){
				setTvpiChartData(res.data)
			}else{
				console.log(res.errorMessage)
			}
		}).catch((e: any) => {
      console.log(e)
    })
  }

  const handleInvestorModeClick = (mode: string) => {
    setSelectedMode(mode);
    if(mode === 'movementDetails' && cashflowChartData.length === 0){
      getCashflowChartData()
    }
  }

  const toggleRow = (row: any) => {
    let rows = document.getElementsByClassName(`row-${row}`)
    for (let i = 0; i < rows.length; i++) {
      if(rows[i].classList.contains('hidden-row')){
        rows[i].classList.remove('hidden-row')
      } else {
        rows[i].classList.add('hidden-row')
      }
    }
  }

  const handleChartSelection = (checked: boolean, vehicle: number) => {
    if(checked){
      setVehiclesSelectedChart([...vehiclesSelectedChart, vehicle])
    } else {
      let aux = vehiclesSelectedChart.filter((item: any) => item !== vehicle)
      setVehiclesSelectedChart(aux)
    }
  }
  
  const handleChangeDetailsBy = (value: string) => {
    setDetailsBy(value);
  
    if (value === 'funds') {
      const firstFund = peipsList[0]?.funds[0];
      setSelectedPeip({
        idPeip: firstFund?.idFund || undefined,
        peip: firstFund?.fund || undefined
      });
    } else if (value === 'investors') {
      const firstInvestor = investorList[0];
      setSelectedInstitution({
        idInstitution: firstInvestor?.idInstitution || undefined,
        institution: firstInvestor?.investor || undefined
      });
    }
  };

  const handleSelectedVehicleRadio = (idVehicle: number) => {
	  setSelectedVehicleRadio(idVehicle)
      getNetAssetValueData(idVehicle, selectedInstitution.idInstitution ?? 0)
  }

  const getNetAssetValueData = async(idVehicle: number, idInstitution: number) => {
   if (idInstitution === 0) return;
    setLoading({ ...loading, chart: true })
    await InvestorPortalApi.getNetAssetValueChart(idVehicle, idInstitution).then((res: any)=>{
			if(res.success){
				setNetAssetValueChart(res.data)
			}else{
				console.log(res.errorMessage)
			}
		}).catch((e: any) => {
      console.log(e)
    })
    setLoading({ ...loading, chart: false }) 
  }

  const generateQuarterOptions = () => {
    const startYear = 2016;
    const currentYear = dayjs().year();
	const today = dayjs(); // Almacenar la fecha actual
    const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => currentYear - i).reverse();
    
        let quarters = [
      { label: 'Q1', date: '03-31' },
      { label: 'Q2', date: '06-30' },
      { label: 'Q3', date: '09-30' },
      { label: 'Q4', date: '12-31' }
    ];
  
    let yearList = [];
  
    for (let i = 0; i < years.length; i++) {
      let yearOptions = [];
      for (let index = 0; index < quarters.length; index++) {
        let quarterValue = `${years[i]}-${quarters[index].date}`;
		// Si estamos en la última fecha de un trimestre, no agregarlo aún
		// Solo se agrega si es un día posterior (por ejemplo, el 01-10-2024)
	    if (dayjs(quarterValue).isSame(today, 'day')) break;
		if (dayjs(quarterValue).isAfter(today)) break;	
        let quarterLabel = `${quarters[index].label} ${years[i]}`;
        yearOptions.push({ label: quarterLabel, value: quarterValue });
      }
  
      if (yearOptions.length > 0) {
        yearList.push({ label: years[i], options: yearOptions });
      }
    }

    if (yearList.length > 0 && yearList[yearList.length - 1].options.length > 0) {
      //Eliminar último trimestre
      yearList[yearList.length - 1].options.pop();
      yearList = yearList.filter((year: any) => year.options.length > 0);

      let lastQuarter = yearList[yearList.length - 1].options.slice(-1)[0];
      let previousQuarter = yearList[yearList.length - 1].options.slice(-2)[0];
  
      setQuarterList(yearList);
      setSelectedDates({ from: previousQuarter.value, to: lastQuarter.value });
    }
  };

  const onOpenTVPIModal = () => {
    setIsTvpiModalOpen(true);
  }

  const onCloseTvpiModal = () => {
    setIsTvpiModalOpen(false);
  }

  return {
    loading,
    handleFundClick,
    selectedPeip,
    peipsList,
    investorList,
    filterInvestors,
    filteredInvestorList,
    detailsBy,
    fundsData,
    downloadExcel,
    quarterList,
    onChangeDates,
    selectedDates,
    selectedInstitution,
    selectedMode, 
    handleInvestorModeClick,
    toggleRow,
    investorVehicleData,
    investorFundData,
    handleChartSelection,
    cashflowChartData,
    handleChangeDetailsBy,
    navChartData,
    tvpiChartData,
    selectedVehicleRadio,
    handleSelectedVehicleRadio,
    netAssetValueChart,
    vehiclesSelectedChart,
    onOpenTVPIModal,
    onCloseTvpiModal,
    isTvpiModalOpen
  };
};

export default useInvestmentsDetails;
