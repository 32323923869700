import "./CommonComponents.scss";

const ReportHeader = (props: any) => {
  const { translations, data } = props;
  const {
    programName,
    date,
    accountDate,
    investorName,
    cif,
    isin,
    participationsType,
  } = data;
  return (
    <header className="report-header">
      <img
        src="https://qualitasfunds.blob.core.windows.net/assets/image-gallery/2023logo.svg"
        alt="Qualitas Logo"
      />
      <div className="header-text">
        <h3>{programName}</h3>
        <p>
          {translations.printDate} {date}{" "}
        </p>
        <p>
         <strong>{investorName}</strong> - {cif}
        </p>
        <p>
          ISIN - {isin} / {translations.participation} {participationsType}
        </p>
      </div>
    </header>
  );
};

export default ReportHeader;
