import Chart, { CommonSeriesSettings } from "devextreme-react/cjs/chart";
import "./CapitalAccountSCR.scss";
import Signatures from "./Common/Signatures";
import ReportHeader from "./Common/ReportHeader";
import dayjs from "dayjs";
import { CaretRightFilled } from "@ant-design/icons";

const CapitalAccountSCR = (props: any) => {
  const { renderTable, formatNumber, translations, reportData, language } = props;

  const {
    summaryChartData,
    valuationData,
    evolutionData,
    kpiData,
    evolutionChartData,
    movementData,
    sharesData,
    programName,
    date,
    accountDate,
    investorName,
    cif,
    isin,
    participationsType,
    capitalCalls,
    commitment,
    distributions,
    socialCapital,
    totalOas,
    premiumShares,
    capitalReduction,
    oasReturn,
    sharesReturn,
	pending,
    dividends,
    valuations,
    participationsNum,
    participationsValue,
    socialCapitalPerc,
	lastSharesValueDate
  } = reportData;

  const reportHeader = () => {
    return (
      <ReportHeader
        translations={translations}
        data={{
          programName,
          date,
          accountDate,
          investorName,
          cif,
          isin,
          participationsType,
        }}
      />
    );
  };

  let formatedLastSharesValueDate = lastSharesValueDate ? dayjs(lastSharesValueDate).format("DD/MM/YYYY") : "";
  return (
    <>
      {/* Page 1 */}
      <div id="capital-account-scr">
        {reportHeader()}
        <main>
          <div className="summary">
            <div className="summary-table">
              <h4>
				<CaretRightFilled className="caret-right"/>
				{translations.informationToday} {accountDate}
			  </h4>
              <table>
                <tr>
                  <td className="bold-value">{translations.commitment}</td>
                  <td>€ {formatNumber(commitment)}</td>
                </tr>
                <tr>
                  <td className="bold-value">{translations.drawdown}</td>
                  <td>€ {formatNumber(capitalCalls)}</td>
                </tr>
                {socialCapital !== 0 && ( 
                  <tr className="italic-values">
                    <td>{translations.shareCapital}</td>
                    <td>€ {formatNumber(socialCapital)}</td>
                  </tr>
                 )}
                {totalOas !== 0 && ( 
                  <tr className="italic-values">
                    <td>
                      {" "}
                      {translations.oas} {(language === 'es') && <sup>1</sup>}
                    </td>
                    <td>€ {formatNumber(totalOas)}</td>
                  </tr>
               )}
                {premiumShares !== 0 && (
                  <tr className="italic-values">
                    <td>{translations.sharePremium}</td>
                    <td>€ {formatNumber(premiumShares)}</td>
                  </tr>
                 )} 
                <tr>
                  <td className="bold-value">{translations.distributions}</td>
                  <td>€ {formatNumber(distributions)}</td>
                </tr>
                {capitalReduction !== 0 && ( 
                  <tr className="italic-values">
                    <td>{translations.capitalReduction}</td>
                    <td>€ {formatNumber(capitalReduction)}</td>
                  </tr>
                 )}
                {oasReturn !== 0 && ( 
                  <tr className="italic-values">
                    <td>
					{translations.returnOas} {(language === 'es') && <sup>1</sup>}
                    </td>
                    <td>€ {formatNumber(oasReturn)}</td>
                  </tr>
                )}
                  <tr>
                    <td>
					{translations.pending}
                    </td>
                    <td>€ {formatNumber(pending)}</td>
                  </tr>
                {sharesReturn !== 0 && ( 
                  <tr className="italic-values">
                    <td>{translations.returnPremium}</td>
                    <td>€ {formatNumber(sharesReturn)}</td>
                  </tr>
               )}
                {dividends !== 0 && (
                  <tr className="italic-values">
                    <td>{translations.dividends}</td>
                    <td>€ {formatNumber(dividends)}</td>
                  </tr>
                 )} 
                <tr className="green-row">
                  <td>{translations.valuation} (NAV)</td>
                  <td>€ {formatNumber(valuations, 2)}</td>
                </tr>
                <tr className="italic-values">
                  <td>Nº {translations.nShares}</td>
                  <td>{formatNumber(participationsNum, 0)}</td>
                </tr>
                <tr className="italic-values">
                  <td>{translations.shareValue} 
					<sup>
					{(oasReturn !== 0 && language === 'es') ? " 2" : " 1"}
					</sup>
				  </td>
                  <td>€ {formatNumber(participationsValue, 4)}</td>
                </tr>
                <tr>
                  <td>% {translations.shareCapital}</td>
                  <td>{formatNumber(socialCapitalPerc, 4) + "%"}</td>
                </tr>
              </table>
				{(oasReturn !== 0 && language === 'es') &&  ( 
					<p>
					<sup>1</sup> {translations.oasLong}
					</p>
				)}
				<p>
					<sup>
					{(oasReturn !== 0 && language === 'es') ? "2" : "1"}
					</sup> {translations.lastShareValue} {formatedLastSharesValueDate}
				</p>
            </div>
            <div className="valuation">
              <h4><CaretRightFilled className="caret-right"/>
			  {translations.valuation} <span className="euros-span">(€)</span>
			  </h4>
              <div id="summary-chart">
                <Chart
                  dataSource={summaryChartData}
                  animation={{ enabled: false }}
                  height={valuationData.length > 7 ? 120 : 155}
                  palette={["#c9c9c9"]}
                  argumentAxis={{
                    label: {
                      overlappingBehavior: "none",
					  font: {
						size: "10px",
					  },
                    },
                    grid: {
                      visible: false,
                    },
                    tick: { visible: false },
                  }}
                  valueAxis={{
                    visible: false,
                    maxValueMargin: 0.05,
                    label: {
                      visible: false,
                    },
                    grid: {
                      visible: false,
                    },
                    showZero: true,
                    tick: { visible: false },
                  }}
                  series={[
                    {
                      rangeValue1Field: "value1",
                      rangeValue2Field: "value2",
                      name: "Value",
                    },
                  ]}
                  legend={{
                    visible: false,
                  }}
                >
                  <CommonSeriesSettings
                    argumentField="column"
                    type="rangebar"
					minBarSize={0}
                    barWidth={50}
                    point={{
                      customizePoint(e: any) {
                        if (e.argument === "Valor Total" || e.argument === "Total Value") {
                          return { color: "#B3D615" };
                        }
                        return null;
                      },
                    }}
                    label={{
                      font: {
                        color: "black",
                        size: "11px",
                      },
                      visible: true,
                      backgroundColor: "none",
                      customizeText(e) {
                        if (e.originalValue === e.point.data.value1) {
                          return;
                        } else if (e.originalValue === 0) {
                          return formatNumber(e.valueText);
                        } else {
                          return formatNumber(
                            Math.abs(e.point.data.value2 - e.point.data.value1), 0
                          );
                        }
                      },
                    }}
                  />
                </Chart>
              </div>
              <h4><CaretRightFilled className="caret-right"/> {translations.nShares}</h4>
              <div id="valuation-table">{renderTable(valuationData, 'valuationData')}</div>
            </div>
          </div>
          <div className="evolution">
            <h4><CaretRightFilled className="caret-right"/> {translations.mainFiguresEvolution} <span className="euros-span"> (€)</span>
			</h4>
            <div id="evolution-table">{renderTable(evolutionData)}</div>
            <h4><CaretRightFilled className="caret-right"/> KPIs</h4>
            <div id="kpi-table">{renderTable(kpiData)}</div>
            <div id="evolution-chart">
				<div className="evolution-legends">
					<div className="evolution-legends-item">
						<div className="green-value"/>
						<p>{translations.shareValue}</p>
					</div>
					<div className="evolution-legends-item">
						<div className="grey-dpi"/>
						<p>DPI</p>
					</div>
					<div className="evolution-legends-item">
						<div className="grey-rvpi"/>
						<p>RVPI</p>
					</div>
				</div>
              <Chart
                palette={["#f0f0f0", "#c9c9c9"]}
                animation={{ enabled: false }}
                dataSource={evolutionChartData}
                height={150}
                customizeLabel={() => {
                  return {
                    backgroundColor: "none",
                  };
                }}
                commonSeriesSettings={{
                  argumentField: "date",
                  type: "stackedbar",
                  barWidth: 50,
                  label: {
                    font: {
						color: "#000",
						size: "11px",
                    },
                    customizeText(e: any) {
					  if(e.value === 0) return "";
                      return formatNumber(e.valueText, 2) + "x";
                    },
                    visible: true,
                    format: {
                      type: "fixedPoint",
                      precision: 2,
                    },
                  },
                }}
                series={[
                  {
                    valueField: "rvpi",
                    name: "RVPI",
                    axis: "tvpi",
                  },
                  {
                    valueField: "dpi",
                    name: "DPI",
                    axis: "tvpi",
                  },
                  {
                    type: "line",
                    valueField: "value",
                    name: translations.shareValue,
                    axis: "value",
					color: "#B3D615",
                    label: {
                      visible: true,
					  //horizontalOffset: 40,
					  verticalOffset: 6,
					  customizeText(e) {
						return formatNumber(e.valueText, 2)
					  },
					  font: {
						color: "#94b110",
						size: "9px",
					  }, 
                    },
                    point: {
                      size: 8,
                    },
                  },
                ]}
                valueAxis={[
                  {
                    name: "tvpi",
                    visible: false,
                    maxValueMargin: 0.05,

                    label: {
                      visible: false,
					  font:{
						size: "10px",
					  },
					  customizeText(e) {
						return e.valueText + "x"
					  }
                    },
                    grid: {
                      visible: false,
                    },
                    tick: { visible: false },
                  },
                  {
                    name: "value",
					position: 'right',
                    visible: false,
					//color: "#B3D615",
					visualRange: {
						startValue: 0,

					},
					label: {
                      visible: false,
					  font:{
						size: "10px",
					  },
                    },
                    grid: {
                      visible: false,
					  
                    },
                    tick: { 
						visible: false,
					 },
                  },
                ]}
				argumentAxis={{
					tick: { visible: false },
					label: {
						font: {
							size: "10px",
							color: "#000",
						}
					}
				}}
                legend={{
				  visible: false,
                  horizontalAlignment: "left",
                  verticalAlignment: "bottom",				  
                }}
              ></Chart>
            </div>
          </div>
        </main>
		<p className="page-number">1 {translations.of} 3</p>
      </div>
      <div style={{ pageBreakAfter: "always" }}></div>
      <div className="page-separator" />

      {/* Page 2 */}
      <div id="capital-account-scr">
        {reportHeader()}
        <main>
          <div className="movements">
            <h4> <CaretRightFilled className="caret-right"/> {translations.historicalMovements}</h4>
            {renderTable(movementData, 'movementData', true)}
          </div>
        </main>
		<p className="page-number">2 {translations.of} 3</p>
	  </div>
      <div style={{ pageBreakAfter: "always" }}></div>
      <div className="page-separator" />

      {/* Page 3 */}
      <div id="capital-account-scr">
        {reportHeader()}
        <main>
          <div className="descriptions">
            <h4>{translations.program}</h4>
            <table>
              <tbody>
                <tr>
                  <td>{translations.legalName}</td>
                  <td>{programName}</td>
                </tr>
                <tr>
                  <td>{translations.registeredOffice}</td>
                  <td>C/ Velazquez N.º31, 2ª Planta, 28001 - Madrid</td>
                </tr>
              </tbody>
            </table>
            <h4>{translations.registrationData}</h4>
            <table>
              <tbody>
                <tr>
                  <td>{translations.shareholder}</td>
                  <td>{investorName}</td>
                </tr>
                <tr>
                  <td>CIF</td>
                  <td>{cif}</td>
                </tr>
              </tbody>
            </table>
            <h4>{translations.titles}</h4>
            <div id="titles-table">{renderTable(sharesData, 'sharesData')}</div>
          </div>
          <Signatures translations={translations} category="scr"/>
        </main>
		<p className="page-number">3 {translations.of} 3</p>
      </div>
    </>
  );
};

export default CapitalAccountSCR;
