import { AxiosServiceAPI, AxiosWebAPI } from "./api";

const ReportsApi = {
    generateCapitalAccount(data:any):Promise<any> {
		const { idInvestor, date, language } = data;
        return new Promise((resolve, reject) => {
            AxiosServiceAPI.get(`${process.env.REACT_APP_WebAPI_BaseURL}/reporting-api/CapitalAccount/GetCapitalAccountData?investorId=${idInvestor}&date=${date}&language=${language}`)
            .then((data) => { 
                if (data !== null) {
                    return resolve(data.data);
                } else {
                    return reject(null);
                }
            })
            .catch((error) => {
				console.log(error);
                return reject(error);
            });
        });
    },
    GenerateCapitalCall(data:any):Promise<any> {
        return new Promise((resolve, reject) => {
            AxiosWebAPI.post(`/PDF/GenerateCapitalCall`,data, {
                responseType: 'arraybuffer',
                headers: {
                  'Accept': 'application/pdf'
                }
            })
            .then((data) => { 
                if (data !== null) {
                    return resolve(data.data);
                } else {
                    return reject(null);
                }
            }).catch((error) => {
                return reject(error);
            });
        });
    },
    GenerateDistributionNotice(data:any):Promise<any> {
        return new Promise((resolve, reject) => {
            AxiosWebAPI.post(`/PDF/GenerateDistributionNotice`,data, {
                responseType: 'arraybuffer',
                headers: {
                  'Accept': 'application/pdf'
                }
            })
            .then((data) => { 
                if (data !== null) {
                    return resolve(data.data);
                } else {
                    return reject(null);
                }
            }).catch((error) => {
                return reject(error);
            });
        });
    },
    GenerateClosing(idInvestor:number):Promise<any> {
        return new Promise((resolve, reject) => {
            AxiosWebAPI.get(`${process.env.REACT_APP_WebAPI_BaseURL}/documentsystem-api/Investor/GetCommitmentLetter?IdInvestor=${idInvestor}`)
            .then((data) => { 
                if (data !== null) {
                    return resolve(data.data);
                } else {
                    return reject(null);
                }
            }).catch((error) => {
                return reject(error);
            });
        });
    },
    GetCapitalCallDates(data: any):Promise<any> {
        let idVehicle =  data.idVehicle;
        let investors = data.investors;
        let query = investors.map((investor:any) => `IdInvestors=${investor}`).join('&');
        return new Promise((resolve, reject) => {
            AxiosWebAPI.get(`${process.env.REACT_APP_WebAPI_BaseURL}/capitalcalls-api/Investors/GetCapitalCallsDates?IdVehicle=${idVehicle}&${query}`)
            .then((data) => { 
                if (data !== null) {
                    return resolve(data.data);
                } else {
                    return reject(null);
                }
            }).catch((error) => {
                return reject(error);
            });
        });
    },
    GetDistributionDates(data: any):Promise<any> {
        let idVehicle =  data.idVehicle;
        let investors = data.investors;
        let query = investors.map((investor:any) => `IdInvestors=${investor}`).join('&');
        return new Promise((resolve, reject) => {
            AxiosWebAPI.get(`${process.env.REACT_APP_WebAPI_BaseURL}/distributions-api/Investors/GetDistributionsDatesByIdVehicle?IdVehicle=${idVehicle}&${query}`)
            .then((data) => { 
                if (data !== null) {
                    return resolve(data.data);
                } else {
                    return reject(null);
                }
            }).catch((error) => {
                return reject(error);
            });
        });
    },
	ViewPDF(data: any):Promise<any> {
		console.log(data);
		let url = `${process.env.REACT_APP_WebAPI_BaseURL}/documentsystem-api/Documents/GetDocumentsByIdRegistry?IdRegistry=${data.idRegistry}&TableRegistry=${data.registry}${data.qFundInvest ? `&QFundInvest=${data.qFundInvest}` : ''}`;
		return new Promise((resolve, reject) => {
			AxiosWebAPI.get(url)
			.then((data) => { 
				if (data !== null) {
					return resolve(data.data);
				} else {
					return reject(null);
				}
			}).catch((error) => {
				return reject(error);
			});
		});
	}

}

export default ReportsApi;