import "./ReportViewer.scss";
import useReporting from "../../../hooks/useReporting";
import { Button, ConfigProvider, Spin } from "antd";
import CapitalAccountFCR from "../Templates/CapitalAccountFCR";
import LanguageSelector from "../../../components/Menu/LanguageSelector";
import { useNavigate } from "react-router-dom";
import CapitalAccountSCR from "../Templates/CapitalAccountSCR";
import { LoadingOutlined } from "@ant-design/icons";

const ReportViewer = (props: any) => {
  const { translations, language, setLanguage, mainColor } = props;

  const {
    previewRef,
    downloadReport,
    formatNumber,
    renderTable,
    vehicleCategory,
    reportType,
    reportData,
    hasPermission,
    loading,
	pdfUrl
  } = useReporting({ language, translations });

  const navigate = useNavigate();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#B3D615",
          colorPrimaryText: "#000000",
          borderRadius: 0,
        },
      }}
    >
      <div className="report-container">
        <iframe
          title="print"
          id="ifmcontentstoprint"
          style={{
            height: "0px",
            width: "0px",
            position: "absolute",
            border: 0,
          }}
        ></iframe>
        <button className="home-button" onClick={() => navigate("/")}>
          <img
            className="q-logo"
            height="30px"
            src="https://qualitasfunds.blob.core.windows.net/assets/image-gallery/reporting/new_logo.png"
            alt="qualitas-funds"
          />
        </button>
			{(reportType !== 'pdf') &&
				<div className="report-options-header">
					<LanguageSelector language={language} setLanguage={setLanguage} />
					{(hasPermission && reportType === 'capital-account') && (
						<Button
						type="primary"
						onClick={downloadReport}
						disabled={loading}
						loading={loading}
						>
						{translations.downloadReport}
						</Button>
					)}
				</div>
			}
        <Spin
          size="large"
          spinning={loading}
          indicator={
            <LoadingOutlined style={{ fontSize: 46, color: mainColor }} />
          }
        >
			{/* PDF DOCUMENTS */}
0		  	{reportType === "pdf" &&
				<iframe 
					src={pdfUrl} 
					title="report" 
					style={{ width: "calc(100vw - 20px)", height: "calc(100vh - 110px)", marginTop: '10px', border: 'none' }}
					></iframe>
			}

			{/* CAPITAL ACCOUNT */}
			{reportType === "capital-account" && 
				<div className="preview">
					<div
					className="template-container"
					id="wrapper"
					ref={previewRef}
					style={{ backgroundColor: "white"}}
					>
					{reportData && hasPermission && (
						<>
						{reportType === "capital-account" &&
							vehicleCategory === "FCR" && (
							<CapitalAccountFCR
								translations={translations}
								renderTable={renderTable}
								formatNumber={formatNumber}
								reportData={reportData}
							/>
							)}
						{reportType === "capital-account" &&
							vehicleCategory === "SCR" && (
							<CapitalAccountSCR
								language={language}
								translations={translations}
								renderTable={renderTable}
								formatNumber={formatNumber}
								reportData={reportData}
							/>
							)}
						</>
					)}
					{

					}
					{!hasPermission && !loading && (
						<div className="no-permission">
						<p>{translations.noPermissionMessage}</p>
						<Button type="primary" onClick={() => window.close()}>
							{translations.closeWindow}
						</Button>
						</div>
					)}
					</div>
				</div>
			}
        </Spin>
      </div>
    </ConfigProvider>
  );
};

export default ReportViewer;
