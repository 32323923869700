import { CaretRightOutlined, DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { formatNumber } from "../../../../utils/Numbers";
import { Select, Spin } from "antd";
import NavChart from "./NavChart";
import TvpiChart from "./TvpiChart";
import { IFundsDetailsProps } from "../IInvestmentsDetails";

const FundsDetails = (props: IFundsDetailsProps) => {
  const {
    translations,
    fundsData,
    downloadExcel,
    quarterList,
    onChangeDates,
    selectedDates,
    loading,
    selectedPeip,
    navChartData,
    tvpiChartData,
    mainColor,
    onOpenTVPIModal
  } = props;

  return (
    <div className="funds-details">
      <div className="details-header">
        <h2>
          {translations.detallePorFondo} | <span>{selectedPeip.peip}</span>
        </h2>
        <button className="download-btn" onClick={()=> downloadExcel('funds-table')}>
          <DownloadOutlined /> {translations.descargarExcel}
        </button>
      </div>
      <Spin
        size="large"
        spinning={loading.table}
        indicator={
          <LoadingOutlined style={{ fontSize: 46, color: mainColor }} />
        }
      >
        <table className="funds-table" id="funds-table">
          <colgroup>
            <col style={{ width: "37%" }} />
            <col style={{ width: "12%" }} />
            <col style={{ width: "9%" }} />
            <col style={{ width: "12%" }} />
            <col style={{ width: "9%" }} />
            <col style={{ width: "12%" }} />
            <col style={{ width: "9%" }} />
          </colgroup>
          <thead>
            <tr>
              <th>{translations.principalesMagnitudes}</th>
              <th colSpan={2} className="grey-background">
                <Select
                  bordered={false}
                  placeholder={translations.hastaTrimestre}
                  options={quarterList}
                  value={selectedDates.to}
                  onChange={(e: string) => onChangeDates(e, "to")}
                />
              </th>
              <th colSpan={2}>
                <Select
                  bordered={false}
                  placeholder={translations.desdeTrimestre}
                  options={quarterList}
                  value={selectedDates.from}
                  onChange={(e: string) => onChangeDates(e, "from")}
                />
              </th>
              <th colSpan={2}>{translations.variacion}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="bold">{translations.compromisoTotal}</td>
              <td className="grey-background">€{formatNumber(fundsData.commitQ2Value)}</td>
              <td className="grey-background">{formatNumber(fundsData.commitQ2Percentage)}% <span className="row-note">(1)</span></td>
              <td >
                €{formatNumber(fundsData.commitQ1Value, 0)}
              </td>
              <td>
                {formatNumber(fundsData.commitQ1Percentage)}% <span className="row-note">(1)</span>
              </td>
              <td>€{formatNumber(fundsData.commitVariation)}</td>
              <td>{formatNumber(fundsData.commitVariationPercentage)}%</td>
            </tr>
            <tr>
              <td>{translations.desembolsoDeParticipes}</td>
              <td className="grey-background">€{formatNumber(fundsData.drawdownQ2Value)}</td>
              <td className="grey-background">{formatNumber(fundsData.drawdownQ2Percentage)}% <span className="row-note">(1)</span></td>
              <td >
                €{formatNumber(fundsData.drawdownQ1Value)}
              </td>
              <td >
                {formatNumber(fundsData.drawdownQ1Percentage)}% <span className="row-note">(1)</span>
              </td>
              <td>€{formatNumber(fundsData.drawdownVariation)}</td>
              <td>{formatNumber(fundsData.drawdownVariationPercentage)}%</td>
            </tr>
            <tr>
              <td>{translations.distribucionesAcumuladas}</td>
              <td className="grey-background">€{formatNumber(fundsData.distQ2Value)}</td>
              <td className="grey-background">{formatNumber(fundsData.distQ2Percentage)}% <span className="row-note">(2)</span></td>
              <td >
                €{formatNumber(fundsData.distQ1Value)}
              </td>
              <td >
                {formatNumber(fundsData.distQ1Percentage)}% <span className="row-note">(2)</span>
              </td>
              <td>€{formatNumber(fundsData.distVariation)}</td>
              <td>{formatNumber(fundsData.distVariationPercentage)}%</td>
            </tr>
            <tr className="border-bottom">
              <td>{translations.compromisoPendiente}</td>
              <td className="grey-background">€{formatNumber(fundsData.pendQ2Value)}</td>
              <td className="grey-background">{formatNumber(fundsData.pendQ2Percentage)}% <span className="row-note">(1)</span></td>
              <td >
                €{formatNumber(fundsData.pendQ1Value)}
              </td>
              <td >
                {formatNumber(fundsData.pendQ1Percentage)}% <span className="row-note">(1)</span>
              </td>
              <td>€{formatNumber(fundsData.pendVariation)}</td>
              <td>{formatNumber(fundsData.pendVariationPercentage)}%</td>
            </tr>
            <tr>
              <td className="bold">{translations.compromisoTotalFondos}</td>
              <td className="grey-background">€{formatNumber(fundsData.commitFundsQ2Value)}</td>
              <td className="grey-background">{formatNumber(fundsData.commitFundsQ2Percentage)}% <span className="row-note">(1)</span></td>
              <td >
                €{formatNumber(fundsData.commitFundsQ1Value)}
              </td>
              <td >
                {formatNumber(fundsData.commitFundsQ1Percentage)}% <span className="row-note">(1)</span>
              </td>
              <td>€{formatNumber(fundsData.commitFundsVariation)}</td>
              <td>{formatNumber(fundsData.commitFundsVariationPercentage)}%</td>
            </tr>
            <tr>
              <td>{translations.desembolsoFondosSubyacentes}</td>
              <td className="grey-background">€{formatNumber(fundsData.drawdownFundsQ2Value)}</td>
              <td className="grey-background">{formatNumber(fundsData.drawdownFundsQ2Percentage)}% <span className="row-note">(2)</span></td>
              <td >
                €{formatNumber(fundsData.drawdownFundsQ1Value)}
              </td>
              <td >
                {formatNumber(fundsData.drawdownFundsQ1Percentage)}% <span className="row-note">(2)</span>
              </td>
              <td>€{formatNumber(fundsData.drawdownFundsVariation)}</td>
              <td>
                {formatNumber(fundsData.drawdownFundsVariationPercentage)}%
              </td>
            </tr>
            <tr className="border-bottom">
              <td>{translations.distribucionesRecibidasFondo}</td>
              <td className="grey-background">€{formatNumber(fundsData.distFundsQ2Value)}</td>
              <td className="grey-background">{formatNumber(fundsData.distFundsQ2Percentage)}% <span className="row-note">(2)</span></td>
              <td >
                €{formatNumber(fundsData.distFundsQ1Value)}
              </td>
              <td >
                {formatNumber(fundsData.distFundsQ1Percentage)}% <span className="row-note">(2)</span>
              </td>
              <td>€{formatNumber(fundsData.distFundsVariation)}</td>
              <td>{formatNumber(fundsData.distFundsVariationPercentage)}%</td>
            </tr>
            <tr>
              <td className="bold">{translations.valoracionCarteraTotal}</td>
              <td className="grey-background">€{formatNumber(fundsData.portfolioQ2Value)}</td>
              <td className="grey-background">{formatNumber(fundsData.portfolioQ2Percentage)}% <span className="row-note">(2)</span></td>
              <td >
                €{formatNumber(fundsData.portfolioQ1Value)}
              </td>
              <td >
                {formatNumber(fundsData.portfolioQ1Percentage)}% <span className="row-note">(2)</span>
              </td>
              <td>€{formatNumber(fundsData.portfolioVariation)}</td>
              <td>{formatNumber(fundsData.portfolioVariationPercentage)}%</td>
            </tr>
            <tr>
              <td>{translations.posicionNetaCaja}</td>
              <td className="grey-background">€{formatNumber(fundsData.netCashQ2Value)}</td>
              <td className="grey-background">{formatNumber(fundsData.netCashQ2Percentage)}% <span className="row-note">(2)</span></td>
              <td >
                €{formatNumber(fundsData.netCashQ1Value)}
              </td>
              <td >
                {formatNumber(fundsData.netCashQ1Percentage)}% <span className="row-note">(2)</span>
              </td>
              <td>€{formatNumber(fundsData.netCashVariation)}</td>
              <td>{formatNumber(fundsData.netCashVariationPercentage)}%</td>
            </tr>
            <tr className="color-background">
              <td>{translations.valorTotalActivoNeto}</td>
              <td>€{formatNumber(fundsData.totalNavQ2Value)}</td>
              <td>{formatNumber(fundsData.totalNavQ2Percentage)}% <span className="row-note">(2)</span></td>
              <td>€{formatNumber(fundsData.totalNavQ1Value)}</td>
              <td>{formatNumber(fundsData.totalNavQ1Percentage)}% <span className="row-note">(2)</span></td>
              <td>€{formatNumber(fundsData.totalNavVariation)}</td>
              <td>{formatNumber(fundsData.totalNavVariationPercentage)}%</td>
            </tr>
            <tr>
              <td>{translations.multiploDistribucionCapital}</td>
              <td className="grey-background"  colSpan={2}>{formatNumber(fundsData.dpiQ2, 2)}x</td>
              <td colSpan={2}>
                {formatNumber(fundsData.dpiQ1, 2)}x
              </td>
              <td>{formatNumber(fundsData.dpiVariation, 2)}x</td>
              <td>{formatNumber(fundsData.dpiVariationPercentage)}%</td>
            </tr>
            <tr>
              <td>{translations.multiploValorResidualCapital}</td>
              <td className="grey-background" colSpan={2}>{formatNumber(fundsData.rpiQ2, 2)}x</td>
              <td  colSpan={2}>
                {formatNumber(fundsData.rpiQ1, 2)}x
              </td>
              <td>{formatNumber(fundsData.rpiVariation, 2)}x</td>
              <td>{formatNumber(fundsData.rpiVariationPercentage)}%</td>
            </tr>
            <tr>
              <td>{translations.multiploTotalValorCapital}</td>
              <td className="grey-background" colSpan={2}>{formatNumber(fundsData.tvpiQ2, 2)}x</td>
              <td  colSpan={2}>
                {formatNumber(fundsData.tvpiQ1, 2)}x
              </td>
              <td>{formatNumber(fundsData.tvpiVariation, 2)}x</td>
              <td>{formatNumber(fundsData.tvpiVariationPercentage)}%</td>
            </tr>
          </tbody>
        </table>
        <div className="notes">
          <p>
            <span>(1)</span> {translations.sobreCompromisoTotal}
          </p>
          <p>
            <span>(2)</span> {translations.sobreeTotalDesembolsado}
          </p>
        </div>
        <h2>
          <span><CaretRightOutlined style={{ color: mainColor }}/> </span> {translations.graficosDelFondo} 
        </h2>
        <div className='fund-charts'>
          <NavChart 
            translations={translations} 
            navChartData={navChartData}
            loading={loading.table ?? false}
            />
          <TvpiChart
            tvpiChartData={tvpiChartData}
            loading={loading.table ?? false}
            translations={translations} 
            onOpenTVPIModal={onOpenTVPIModal}
          />
        </div>
      </Spin>
    </div>
  );
};

export default FundsDetails;
