import { CaretRightOutlined, SearchOutlined } from "@ant-design/icons";
import { Collapse, Input, Skeleton } from "antd";
import { IFundsSidebarProps } from "./IInvestmentsDetails";

const { Panel } = Collapse;

const FundsSidebar = (props: IFundsSidebarProps) => {
  const {
    translations,
    loading,
    peipsList,
    selectedPeip,
    handleFundClick,
    filteredInvestorList,
    filterInvestors,
    selectedInstitution,
    detailsBy,
    handleChangeDetailsBy,
  } = props;

  return (
    <div className="funds-sidebar">
        <div className='search-by-container'>
          {['funds', 'investors'].map(option => (
            <button
            key={option}
            onClick={() => handleChangeDetailsBy(option)}
            className={`search-by-btn ${detailsBy === option ? 'selected-btn' : ''}`}
            >
              {translations[option === 'funds' ? 'porFondo' : 'porInversor']}
            </button>
          ))}
      </div>

      {loading.menu ? (
        <div className="skeleton-container">
          <Skeleton active style={{ width: "210px" }} />
        </div>
      ) : detailsBy === 'funds' ? (
        <div className="option-list">
          {/* DETAILS BY FUND */}
          <h3>{translations.buscarPorFondo}</h3>
          <Collapse
            ghost
            className="collapse-funds"
            expandIconPosition="end"
            defaultActiveKey={["0"]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            {peipsList.length !== 0 &&
              peipsList.map((peip: any, index: number) => {
                return (
                  <Panel header={translations[peip.fundCategory]} key={index}>
                    <div className="funds-list">
                      {peip.funds.map((x: any) => {
                        return (
                          <button
                            className={
                              selectedPeip.idPeip === x.idFund ? "selectedPeip" : ""
                            }
                            onClick={() => handleFundClick(x.idFund, 'funds')}
                            key={x.idFund}
                          >
                            <p>{x.fund}</p>
                          </button>
                        );
                      })}
                    </div>
                  </Panel>
                );
              })}
            {(peipsList.length === 0 && !loading.menu) && 
              <p className="noInvestorsAsociated">
                {translations.noInvestorsAsociados}
              </p>
            }
          </Collapse>
        </div>
      ) : (
        /* DETAILS BY INVESTOR */
        <div className="option-list">
          <h3>{translations.buscarPorInvesor}</h3>
          <Input
            placeholder={translations.busquedaHolder}
            onChange={(e: any)=> filterInvestors(e.target.value)}
            style={{ width: 240 }}
            bordered={false}
            suffix={<SearchOutlined />}
            className='search-btn'
          />
          <Collapse
            ghost
            className="collapse-funds"
            expandIconPosition="end"
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            {filteredInvestorList.map((investor: any, index: number) => {
                return (
                    <div className="investor-item" key={index}>
                        <button
                          className={selectedInstitution.institution === investor.investor ? "selectedPeip" : ""}
                          onClick={() => handleFundClick(investor.idInstitution, 'investors')}
                          key={index}
                        >
                          <p>{investor.investor}</p>
                          <CaretRightOutlined />
                        </button>
                    </div>
                );
              })}
            {(peipsList.length === 0 && !loading.menu) && 
              <p className="noInvestorsAsociated">
                {translations.noInvestorsAsociados}
              </p>
            }
          </Collapse>
        </div>
      )}
    </div>
  );
};

export default FundsSidebar;
