import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Format,
  Label,
  Legend,
  Series,
} from "devextreme-react/chart";
import { INavChartProps } from "../IInvestmentsDetails";
import { formatNumber } from "../../../../utils/Numbers";

const NavChart = (props: INavChartProps) => {
  const { navChartData, translations, loading, } = props;
  return (
    <div className="fund-chart">
      <h4>NAV</h4>
      <Chart
        dataSource={navChartData}
        animation={{ enabled: true }}
        height={280}
        customizePoint={(e) => {
          if (e.minValue === 0) {
            return { color: "#6B6B6B" };
          }
          return {};
        }}
        palette={["#dbdbdb"]}
        argumentAxis={{
          tick: { visible: false },
        }}
        valueAxis={{
          visible: false,
          maxValueMargin: 0.05,
          label: {
            visible: false,
          },
          grid: {
            visible: false,
          },
          showZero: true,
          tick: { visible: false },
        }}
      >
        <CommonSeriesSettings
          argumentField="column"
          type="rangebar"
          minBarSize={2}
          barWidth={80}
        >
          <Label
            visible={true}
            backgroundColor="none"
            font={{ color: "black", size: "10px" }}
            customizeText={(e: any) => {
              if (e.originalValue === e.point.data.value1) {
                return "";
              } else if (e.originalValue === 0) {
                return "€" + e.valueText + "M";
              } else {
                return ("€" +formatNumber((e.point.data.value2 - e.point.data.value1), 2) + " M");
              }
            }}
          >
            <Format type="fixedPoint" precision={2} />
          </Label>
        </CommonSeriesSettings>
        <Series
          rangeValue1Field="value1"
          rangeValue2Field="value2"
          name="Value"
        />
        <ArgumentAxis>
          <Label
            visible={true}
            font={{ color: "black", size: "10px" }}
            overlappingBehavior="hide"
            customizeText={(e: any) => translations[e.value]}
          />
        </ArgumentAxis>
        <Legend visible={false} />
      </Chart>
      {(navChartData.length === 0 && !loading) && 
        <div className="no-data-found">
          {translations.noDataFound}
        </div>
      }
    </div>
  );
};

export default NavChart;
