import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, Skeleton } from "antd";
import { IDocumentsSidebarProps } from "./IDocuments";
const { Panel } = Collapse;


const DocumentsSidebar = (props: IDocumentsSidebarProps) => {
  const {
    selectedPeip,
    handleChangePeip,
    translations,
    peipList,
    documentsBy,
    handleChangeDetailsBy,
    loading,
    selectedPeipDocuments
  } = props;

  return (
      <div className="documents-sidebar">
        <div className="documents-btns-container">
          {["funds", "investors"].map((option) => (
            <button
              key={option}
              onClick={() => handleChangeDetailsBy(option)}
              className={`documents-by-btn ${
                documentsBy === option ? "selected-btn" : ""
              }`}
            >
              {
                translations[
                  option === "funds" ? "documentosDelFondo" : "generarDocumento"
                ]
              }
            </button>
          ))}
        </div>
        <h3>
          {documentsBy === "funds"
            ? translations.documentosDelFondo
            : translations.generarDocumento}
        </h3>
          <Collapse
            ghost
            className="collapse-funds"
            expandIconPosition="end"
            defaultActiveKey={["0"]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            {peipList.length !== 0 &&
              peipList.map((peip: any, index: number) => {
                return (
                  <Panel header={translations[peip.fundCategory]} key={index}>
                    <div className="funds-list">
                      {peip.funds.map((x: any) => {
                        return (
                          <button
                            className={
                              documentsBy === "investors" ?
                              selectedPeip === x.idFund ? "selectedOption" : "" 
                              : selectedPeipDocuments === x.idFund ? "selectedOption" : ""
                            }
                            onClick={() => handleChangePeip(x.idFund, documentsBy)}
                            key={x.idFund}
                          >
                            <p>{x.fund}</p>
                          </button>
                        );
                      })}
                    </div>
                  </Panel>
                );
              })}
            {peipList.length === 0 && !loading && (
              <p className="noInvestorsAsociated">
                {translations.noInvestorsAsociados}
              </p>
            )}
            {peipList.length === 0 && loading && (
              <div className="skeleton-container">
                <Skeleton active style={{ width: "210px" }} />
              </div>
            )}
          </Collapse>
      </div>
  );
};

export default DocumentsSidebar;
