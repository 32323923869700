import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Format,
  Label,
  Legend,
  Series,
  Tooltip,
} from "devextreme-react/chart";
import { ICashflowChartProps } from "../IInvestmentsDetails";
import { formatNumber } from "../../../../utils/Numbers";

const CashflowChart = (props: ICashflowChartProps) => {
  const { chartData, translations, loading, mainColor } = props;

  const customizeTooltip = (pointInfo: any) => {
    if(pointInfo.seriesName === 'cashflow') {
      return {
        text: `${translations.distributions}: €${formatNumber(pointInfo.rangeValue1)}
        ${translations.capitalCalls}: €${formatNumber(pointInfo.rangeValue2)}`,
      };
    }
    return {text: `${translations.acumulado}: €${formatNumber(pointInfo.value)}`};
}

  return (
    <div className="cashflow-chart">
      <Chart
        animation={true}
        dataSource={chartData}
        palette={["#6B6B6B"]}
        height="340px"
        argumentAxis={{
          visible: true,
          label: {
            visible: true,
          },
          tick: { visible: false },
        }}
        valueAxis={{
          visible: true,
          maxValueMargin: 0.05,
          label: {
            visible: true,
            customizeText: function (e: any) {
              return `€${e.valueText}`;
            },
          },
          grid: {
            visible: true,
            color: "#f0f0f0",
          },
          showZero: true,
          tick: { visible: false },
          constantLines: [
            {
              value: 0,
              color: "#000000",
              dashStyle: "dash",
              width: 1,
              label: {
                visible: false,
              },
            },
          ],
        }}
      >
        <CommonSeriesSettings
          argumentField="column"
          type="rangebar"
          minBarSize={2}
          barWidth={80}
        >
          <Label
            visible={true}
            backgroundColor="none"
            font={{ color: "black", size: "10px" }}
            customizeText={function (e: any) {
              if(e.valueText === '0') return '';
              return e.valueText;
            }}
          >
            <Format type="fixedPoint" precision={0} />
          </Label>
        </CommonSeriesSettings>
        <Series
          rangeValue1Field="value1"
          rangeValue2Field="value2"
          name="cashflow"
          label={{
            visible: true,
            overlappingBehavior: "hide",
          }}
        />
        <Series
          valueField="cumulativeCount"
          type="spline"
          name="cumulative"
          color={mainColor}
          label={{
            visible: false,
          }}
        >
        </Series>
        <ArgumentAxis>
          <Label visible={true} font={{ color: "black", size: "10px" }} />
        </ArgumentAxis>
        <Legend visible={false} />
        <Tooltip enabled={true} customizeTooltip={customizeTooltip}/>
      </Chart>
      {(chartData.length === 0 && !loading) && 
        <div className="no-data-found">
          {translations.noDataFound}
        </div>
      }
    </div>
  );
};

export default CashflowChart;
