const VideosIcon = () => {
  return (
<svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
  <path className="cls-1" d="m27.47,0H2.53C1.14,0,0,1.14,0,2.53v18.41c0,1.4,1.14,2.53,2.53,2.53h9.7v2.42l-3.58,1.74c-.11.06-.19.17-.19.3v1.74c0,.18.15.33.33.33h12.41c.18,0,.33-.15.33-.33v-1.74c0-.13-.07-.24-.19-.3l-3.58-1.74v-2.42h9.7c1.4,0,2.53-1.14,2.53-2.53V2.53c0-1.4-1.14-2.53-2.53-2.53Zm-10.18,26.4l3.58,1.74v1.2h-11.75v-1.2l3.58-1.74c.11-.06.19-.17.19-.3v-2.62h4.21v2.62c0,.13.07.24.19.3Zm12.05-5.45c0,1.03-.84,1.87-1.87,1.87H2.53c-1.03,0-1.87-.84-1.87-1.87V2.53c0-1.03.84-1.87,1.87-1.87h24.94c1.03,0,1.87.84,1.87,1.87v18.41Z"/>
  <path className="cls-1" d="m27.47,2.05H2.53c-.26,0-.48.22-.48.48v15.77c0,.26.22.48.48.48h24.94c.26,0,.48-.22.48-.48V2.53c0-.26-.22-.48-.48-.48Zm-.18,16.07H2.71V2.71h24.57v15.41Z"/>
  <g>
    <path className="cls-1" d="m13.42,7.82l4.51,2.6-4.51,2.6v-5.21m0-.64c-.11,0-.22.03-.32.09-.2.11-.32.33-.32.56v5.21c0,.23.12.44.32.56.1.06.21.09.32.09s.22-.03.32-.09l4.51-2.6c.2-.11.32-.33.32-.56s-.12-.44-.32-.56l-4.51-2.6c-.1-.06-.21-.09-.32-.09h0Z"/>
    <path className="cls-1" d="m15,5.13c2.92,0,5.29,2.37,5.29,5.29s-2.37,5.29-5.29,5.29-5.29-2.37-5.29-5.29,2.37-5.29,5.29-5.29m0-.64c-3.27,0-5.93,2.66-5.93,5.93s2.66,5.93,5.93,5.93,5.93-2.66,5.93-5.93-2.66-5.93-5.93-5.93h0Z"/>
  </g>
</svg>  )
}

export default VideosIcon