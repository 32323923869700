import axios from "axios";
import { notifyError } from "../utils/Fn";

let apiConfig = {
	getAuthToken: () => '',
	userId: ''
};

const initAPIs = (getAuthToken: () => string, userId: any) => {
	apiConfig.getAuthToken = getAuthToken;
	apiConfig.userId = userId;
};

const AxiosWebAPI = axios.create({
    baseURL: process.env.REACT_APP_WebApiURL,
    headers: {
		'Access-Control-Allow-Origin' : '*',
		'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }
});

const AxiosServiceAPI = axios.create({
	baseURL: process.env.REACT_APP_ServiceUrl,
	headers: {
		'Access-Control-Allow-Origin' : '*',
		'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
	}
});

AxiosWebAPI.interceptors.request.use(function (config) {
    if(config?.headers){
        config.headers['Authorization'] = `Bearer ${apiConfig.getAuthToken()}`;
    }
    return config;
});

AxiosServiceAPI.interceptors.request.use(function (config) {
	if(config?.headers){
		config.headers['Authorization'] = `Bearer ${apiConfig.getAuthToken()}`;
	}
  	return config;
});


AxiosWebAPI.interceptors.response.use(undefined, async function (error) {
    if (error.response.status === 401) {
      	window.location.href = '/login';
    }
	if(error.response.status === 500){
		notifyError(
			error.config?.baseURL, 
			error.config?.url,
			apiConfig.userId, 
			error.config?.data,
			apiConfig.getAuthToken()
		);
		window.location.href = '/UnderMaintenance';
	}
});

AxiosServiceAPI.interceptors.response.use(undefined, async function (error) {
  	if (error.response.status === 401) {
    	window.location.href = '/login';
  	}
	  if(error.response.status === 500){
		notifyError(
			error.config?.baseURL, 
			error.config?.url,
			apiConfig.userId, 
			error.config?.data,
			apiConfig.getAuthToken()
		);
		window.location.href = '/UnderMaintenance';
	}
});

export { AxiosWebAPI, AxiosServiceAPI, initAPIs };