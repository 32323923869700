import { DoubleRightOutlined } from '@ant-design/icons'

const Welcome = (props: any) => {
  const {mainColor, translations, logo} = props;
  return (
    <div className="welcome-page">
        <img alt="logo" src={logo || 'https://qualitasfunds.blob.core.windows.net/assets/image-gallery/reporting/new_logo.png'} />
        <h2>{translations.welcomeTo} <br/>
            <DoubleRightOutlined className="lines-moving" style={{fontSize: 30, color: mainColor}} /> <span>{translations.investorPortal}</span>
        </h2>
    </div>
  )
}

export default Welcome