
import { useLocation } from 'react-router-dom';
import './NavigationMenu.scss'
import { Link } from 'react-router-dom';
import HomeIcon from '../../Assets/Icons/HomeIcon';
import DocumentsIcon from '../../Assets/Icons/DocumentsIcon';
import SummaryIcon from '../../Assets/Icons/SummaryIcon';
import PlanificarIcon from '../../Assets/Icons/PlanificarIcon';
import VideosIcon from '../../Assets/Icons/VideosIcon';

const NavigationMenu = (props: any) => {
const {children, translations} = props
 const location = useLocation();

 const menuItems = [
    { path: '/', icon: <HomeIcon />, label: translations.home },
    { path: '/Details', icon: <SummaryIcon />, label: translations.resumen },
    { path: '/YourInvestments', icon: <PlanificarIcon />, label: translations.analisis },
    { path: '/Media', icon: <VideosIcon />, label: translations.media },
    { path: '/Documents', icon: <DocumentsIcon />, label: translations.documentos },
  ];

  return (
    <div className='investor-portal-viewport'>
        <div className='navigation-menu'>
            {menuItems.map((item) => (
              <div className="navigation-menu-item-container">
                <Link key={item.path} to={item.path} className={`navigation-menu_item ${location.pathname === item.path ? 'selected' : ''}`}>
                  <span className="navigation-menu-icon">{item.icon}</span>
                  <div className='navigation-menu-item-hover'>
                    <p>{item.label}</p>
                  </div>
                </Link>
              </div>
            ))}
        </div>
        {children}
    </div>
  )
}

export default NavigationMenu