const HomeIcon = () => {
  return (
    <svg
      id="Capa_1"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
    >
      <path
        className="cls-1"
        d="m29.53,10.73c.1.05.22.04.31-.02.09-.06.15-.16.15-.27v-3.22c0-.12-.07-.24-.18-.29L15.12.03c-.09-.04-.19-.04-.27,0L.17,6.94c-.11.05-.18.16-.18.29v3.22c0,.11.06.21.15.27.05.03.11.05.17.05.05,0,.09-.01.14-.03l2.01-.95v16.51H.31c-.18,0-.32.14-.32.32v3.07c0,.18.14.32.32.32h29.36c.18,0,.32-.14.32-.32v-3.07c0-.18-.14-.32-.32-.32h-2.15V9.79l2.01.95ZM.63,7.43L14.99.67l14.36,6.76v2.52L15.12,3.25s-.09-.03-.14-.03-.09.01-.14.03L.63,9.94v-2.52Zm28.72,21.94H.63v-2.43h28.72v2.43Zm-11.21-3.07h-6.29v-8.28h6.29v8.28Zm8.74,0h-8.1v-8.6c0-.18-.14-.32-.32-.32h-6.94c-.18,0-.32.14-.32.32v8.6H3.1V9.49L14.99,3.89l11.89,5.59v16.81Z"
      />
      <path
        className="cls-1"
        d="m5.18,11.36c0,.18.14.32.32.32h19.01c.18,0,.32-.14.32-.32s-.14-.32-.32-.32H5.5c-.18,0-.32.14-.32.32Z"
      />
    </svg>
  );
};

export default HomeIcon;
