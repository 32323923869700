

const SummaryIcon = () => {
  return (
<svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
  <path className="cls-1" d="m25.65,0H4.35c-.91,0-1.65.74-1.65,1.65v26.7c0,.91.74,1.65,1.65,1.65h21.29c.91,0,1.65-.74,1.65-1.65V1.65c0-.91-.74-1.65-1.65-1.65Zm-15.81,4.06h10.32c.18,0,.33-.15.33-.33v-1.28h0c.89,0,1.61.72,1.61,1.61s-.72,1.61-1.61,1.61h-10.99c-.89,0-1.61-.72-1.61-1.61s.72-1.61,1.61-1.61h0v1.28c0,.18.15.33.33.33ZM19.83.66v2.74h-9.66V.66h9.66Zm6.81,27.69c0,.55-.44.99-.99.99H4.35c-.55,0-.99-.44-.99-.99V1.65c0-.55.44-.99.99-.99h5.16v1.13h0c-1.25,0-2.27,1.02-2.27,2.27s1.02,2.27,2.27,2.27h10.99c1.25,0,2.27-1.02,2.27-2.27s-1.02-2.27-2.27-2.27h0V.66h5.16c.55,0,.99.44.99.99v26.7Z"/>
  <path className="cls-1" d="m10.27,9.4c-.15-.1-.36-.06-.46.09l-1.4,2.07-1.4-2.07c-.1-.15-.31-.19-.46-.09-.15.1-.19.31-.09.46l1.67,2.48c.06.09.16.15.27.15s.21-.05.27-.15l1.67-2.48c.1-.15.06-.36-.09-.46Z"/>
  <path className="cls-1" d="m23.26,10.58h-10.73c-.18,0-.33.15-.33.33s.15.33.33.33h10.73c.18,0,.33-.15.33-.33s-.15-.33-.33-.33Z"/>
  <path className="cls-1" d="m10.27,15.55c-.15-.1-.36-.06-.46.09l-1.4,2.07-1.4-2.07c-.1-.15-.31-.19-.46-.09-.15.1-.19.31-.09.46l1.67,2.48c.06.09.16.15.27.15s.21-.05.27-.15l1.67-2.48c.1-.15.06-.36-.09-.46Z"/>
  <path className="cls-1" d="m23.26,16.73h-10.73c-.18,0-.33.15-.33.33s.15.33.33.33h10.73c.18,0,.33-.15.33-.33s-.15-.33-.33-.33Z"/>
  <path className="cls-1" d="m10.27,21.69c-.15-.1-.36-.06-.46.09l-1.4,2.07-1.4-2.07c-.1-.15-.31-.19-.46-.09-.15.1-.19.31-.09.46l1.67,2.48c.06.09.16.15.27.15s.21-.05.27-.15l1.67-2.48c.1-.15.06-.36-.09-.46Z"/>
  <path className="cls-1" d="m23.26,22.88h-10.73c-.18,0-.33.15-.33.33s.15.33.33.33h10.73c.18,0,.33-.15.33-.33s-.15-.33-.33-.33Z"/>
</svg>  )
}

export default SummaryIcon
