import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Label,
  Series,
} from "devextreme-react/chart";
import { TvpiChart as TvpiChartModel } from "../../../../Modelos/Investors.model";
import { FullscreenOutlined } from "@ant-design/icons";
import { Button } from "antd";

const TvpiChart = (props: {tvpiChartData: TvpiChartModel[], loading: boolean, translations: any, onOpenTVPIModal: any}) => {
  const { tvpiChartData, loading, translations, onOpenTVPIModal } = props;

  return (
    <div className="fund-chart">
      <div className="chart-title">
        <h4>TVPI</h4>
        <Button onClick={onOpenTVPIModal} type="text" icon={<FullscreenOutlined />} />
      </div>
        <Chart
        dataSource={tvpiChartData}
        palette={["#a8a6a6", "#dbdbdb"]}
        resolveLabelOverlapping="hide"
        legend={{ visible: true, verticalAlignment: "top", horizontalAlignment: "center"}}
        height={280}
        animation={false}
        argumentAxis={{
            tick: { visible: false },
          }}
        valueAxis={{
            visible: false,
            maxValueMargin: 0.05,
            grid: {
            visible: false,
            },
            showZero: true,
            tick: { visible: false },
            label: {
            visible: false,
            },
        }}
        >
        <CommonSeriesSettings
            argumentField="quarter"
            type="stackedbar"
            barWidth={50}
        >
          <Label
            visible={true}
            backgroundColor="none"
            font={{ color: "black", size: "10px" }}
            customizeText={(e) => {
              if(e.valueText === "0") return "";
                const formattedValue = e.valueText.replace(".", ",");
                return `${formattedValue}x`;
            }}
          />
        </CommonSeriesSettings>
        <ArgumentAxis>
          <Label
              visible={true}
              font={{ color: "black", size: "10px" }}
              overlappingBehavior="hide"
            />
        </ArgumentAxis>
        <Series valueField="rpi" name="RPI" />
        <Series valueField="dpi" name="DPI" />
        </Chart>
        {(tvpiChartData.length === 0 && !loading) && 
        <div className="no-data-found">
          {translations.noDataFound}
        </div>
      }
    </div>

  );
};

export default TvpiChart;
