import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Label,
  Series,
} from "devextreme-react/chart";
import { formatConcept } from "../../../../utils/Numbers";

const NetAssetValueChart = (props: any) => {
  const { netAssetValueChart, translations, loading } = props;
  return (
    <div className="asset-value-chart">
      <h4>{translations.evolucionValorLiquidativo}</h4>
      <Chart
        dataSource={netAssetValueChart}
        palette={["#a8a6a6", "#dbdbdb"]}
        height={280}
        animation={true}
        argumentAxis={{
          tick: { visible: false },
        }}
        valueAxis={{
          visible: false,
          maxValueMargin: 0.05,
          grid: {
            visible: false,
          },
          showZero: true,
          tick: { visible: false },
          label: {
            visible: false,
          },
        }}
      >
        <CommonSeriesSettings argumentField="quarter" type="line" barWidth={50}>
          <Label
            visible={true}
            backgroundColor="none"
            font={{ color: "black", size: "10px" }}
            customizeText={(e) => {
              return formatConcept(e.value, 4);
            }}
          />
        </CommonSeriesSettings>
        <ArgumentAxis>
          <Label
            visible={true}
            font={{ color: "black", size: "10px" }}
            overlappingBehavior="hide"
          />
        </ArgumentAxis>
        <Series valueField="value" name="RPI" />
      </Chart>
      {netAssetValueChart.length === 0 && !loading && (
        <div className="no-data-found">{translations.noDataFound}</div>
      )}
    </div>
  );
};

export default NetAssetValueChart;
