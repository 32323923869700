import Navbar, { NavbarApi, useNavbar } from "@qualitas/navbar";
import LanguageSelector from "./LanguageSelector";

const MenuNavbar = (props: any) => {
  const {
    baseUrl,
    getAuthToken,
    logout,
    authProps,
    sendResetPasswordEmail,
    navigate,
    language,
    setLanguage,
  } = props;

  let migratedUrl = process.env.REACT_APP_InvestorPortal;
  let notMigratedUrl = process.env.REACT_APP_InvestorPortal;

  const idSystemMaster = 0;
  const api = NavbarApi(baseUrl, getAuthToken);
  const { translations, ...NavbarProps} = useNavbar({
    api,
    idSystemMaster,
    userData: { ...authProps},
    migratedUrl,
    notMigratedUrl,
    navigate,
    sendResetPasswordEmail: () => sendResetPasswordEmail(authProps.userEmail),
    language
  });

  return (
      <Navbar
        {...NavbarProps}
        userName={NavbarProps.menuName ?? ""}
        logout={logout}
        reportIncident={() => console.log("report")}
        isBackoffice={false}
        translations={translations}
        extraContent={
          <LanguageSelector setLanguage={setLanguage} language={language} />
        }
      />
  );
};

export default MenuNavbar;
