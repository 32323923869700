const PlanificarIcon = () => {
  return (
  <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <path className="cls-1" d="m29.7,9s-4.06-6.3-4.92-7.5l-.1-.14c-.81-1.15-1.42-1.82-3.67-.96-.18.07-.27.27-.2.45.07.18.27.27.45.2,1.86-.71,2.17-.26,2.86.71l.1.14c.63.89,2.88,4.36,4.1,6.24-.07,0-.14-.01-.21-.01h-9.15c-.91,0-1.67.64-1.85,1.5-.62-.42-1.35-.65-2.11-.65s-1.49.23-2.11.65c-.18-.85-.94-1.5-1.85-1.5H1.89c-.07,0-.14,0-.21.01,1.22-1.88,3.47-5.35,4.1-6.24l.1-.14c.68-.97,1-1.42,2.86-.71.18.07.38-.02.45-.2.07-.18-.02-.38-.2-.45-2.25-.86-2.86-.2-3.67.96l-.1.14C4.36,2.7.3,8.99.3,9,.11,9.29,0,9.64,0,10.01v3.67C0,14.72.85,15.57,1.89,15.57h9.15c1.04,0,1.89-.85,1.89-1.89v-3.2c1.17-1.07,2.97-1.07,4.13,0v3.2c0,1.04.85,1.89,1.89,1.89h9.15c1.04,0,1.89-.85,1.89-1.89v-3.67c0-.37-.11-.72-.3-1.02Zm-17.46,4.68c0,.66-.54,1.2-1.2,1.2H1.89c-.66,0-1.2-.54-1.2-1.2v-3.67c0-.66.54-1.2,1.2-1.2h9.15c.66,0,1.2.54,1.2,1.2v3.67Zm17.07,0c0,.66-.54,1.2-1.2,1.2h-9.15c-.66,0-1.2-.54-1.2-1.2v-3.67c0-.66.54-1.2,1.2-1.2h9.15c.66,0,1.2.54,1.2,1.2v3.67Z"/>
    <path className="cls-1" d="m28.27,21H4.5c-.09,0-.18.04-.25.1L.1,25.25c-.14.14-.14.36,0,.49l4.15,4.15c.07.07.15.1.25.1h23.77c.95,0,1.73-.78,1.73-1.73v-5.54c0-.95-.78-1.73-1.73-1.73ZM3,27.66c.5-.61.77-1.36.77-2.16s-.27-1.55-.77-2.16l1.5-1.5c.89,1.01,1.38,2.29,1.38,3.65s-.49,2.64-1.38,3.65l-1.5-1.5Zm3.27-.24h15.15c.19,0,.35-.16.35-.35s-.16-.35-.35-.35H6.44c.16-.81.16-1.64,0-2.45h14.97c.19,0,.35-.16.35-.35s-.16-.35-.35-.35H6.26c-.22-.67-.55-1.31-1-1.89h18.56v7.61H5.26c.45-.58.78-1.22,1-1.89Zm-5.42-1.92l1.66-1.66c.37.47.57,1.05.57,1.66s-.2,1.19-.57,1.66l-1.66-1.66Zm28.47,2.77c0,.57-.46,1.04-1.04,1.04h-3.76v-7.61h3.76c.57,0,1.04.46,1.04,1.04v5.54Z"/>
  </svg>  
)
}

export default PlanificarIcon
