import { AxiosWebAPI } from './api';

export const InvestorPortalApi = {
    getMenuItems() {
        return new Promise((resolve, reject) => {
            AxiosWebAPI.get(`${process.env.REACT_APP_WebAPI_BaseURL}/investorportal-api/Menu/GetFundsMenu`)
                .then((data) => { 
                    if (data !== null) {
                        return resolve(data.data);
                    } else {
                        return reject(null);
                    }
                }).catch((error) => {
                    return reject(error);
                });
        });
    },
    getQuarterInvestorData(pageNumber: number, pageSize: number, language: string, queryName?: string) {
        return new Promise((resolve, reject) => {

			let query = `?pageNumber=${pageNumber}&pageSize=${pageSize}&language=${language}${queryName ? `&queryName=${queryName}`: ''}`;
            AxiosWebAPI.get(`${process.env.REACT_APP_WebAPI_BaseURL}/investorportal-api/Investor/GetQuarterInvestorData${query}`)
                .then((data) => { 
                    if (data !== null) {
                        return resolve(data.data);
                    } else {
                        return reject(null);
                    }
                }).catch((error) => {
                    return reject(error);
                });
        });
    },
    getInvestorVehiclesData(idInstitution: number) {
        return new Promise((resolve, reject) => {
            AxiosWebAPI.get(`${process.env.REACT_APP_WebAPI_BaseURL}/investorportal-api/Investor/GetInvestorVehiclesData?institutionId=${idInstitution}`)
                .then((data) => { 
                    if (data !== null) {
                        return resolve(data.data);
                    } else {
                        return reject(null);
                    }
                }).catch((error) => {
                    return reject(error);
                });
        });
    },
    getInvestorFundsData(idInstitution: number) {
        return new Promise((resolve, reject) => {
            AxiosWebAPI.get(`${process.env.REACT_APP_WebAPI_BaseURL}/investorportal-api/Investor/GetInvestorFundsData?institutionId=${idInstitution}`)
                .then((data) => { 
                    if (data !== null) {
                        return resolve(data.data);
                    } else {
                        return reject(null);
                    }
                }).catch((error) => {
                    return reject(error);
                });
        });
    },
    getFundFigures(data: any) {
        let query = `IdPeip=${data.peipId}&Date1=${data.date1}&Date2=${data.date2}`;
        return new Promise((resolve, reject) => {
            AxiosWebAPI.get(`${process.env.REACT_APP_WebAPI_BaseURL}/investorportal-api/FundFigures/GetFundDetails?${query}`)
                .then((data) => { 
                    if (data !== null) {
                        return resolve(data.data);
                    } else {
                        return reject(null);
                    }
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    getInvestorList(idPeip: number) {
        return new Promise((resolve, reject) => {
            AxiosWebAPI.get(`${process.env.REACT_APP_WebAPI_BaseURL}/investorportal-api/Investor/GetInvestorList?IdPeip=${idPeip}`)
                .then((data) => { 
                    if (data !== null) {
                        return resolve(data.data);
                    } else {
                        return reject(null);
                    }
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    getNavChartData(params: any) {
        const { idPeip, firstQuarter, lastQuarter } = params;
        return new Promise((resolve, reject) => {
            AxiosWebAPI.get(`${process.env.REACT_APP_WebAPI_BaseURL}/investorportal-api/Chart/GetNavChartData?IdPeip=${idPeip}&FirstQuarter=${firstQuarter}&LastQuarter=${lastQuarter}`)
                .then((data) => { 
                    if (data !== null) {
                        return resolve(data.data);
                    } else {
                        return reject(null);
                    }
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    getTVPIChartData(idPeip: number) {
        return new Promise((resolve, reject) => {
            AxiosWebAPI.get(`${process.env.REACT_APP_WebAPI_BaseURL}/investorportal-api/Chart/GetTVPIChartData?IdPeip=${idPeip}`)
                .then((data) => { 
                    if (data !== null) {
                        return resolve(data.data);
                    } else {
                        return reject(null);
                    }
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    getNetAssetValueChart(idVehicle: number, idInstitution: number) {
        return new Promise((resolve, reject) => {
            AxiosWebAPI.get(`${process.env.REACT_APP_WebAPI_BaseURL}/investorportal-api/Chart/GetNetAssetValueChart?idVehicle=${idVehicle}&idInstitution=${idInstitution}`)
                .then((data) => { 
                    if (data !== null) {
                        return resolve(data.data);
                    } else {
                        return reject(null);
                    }
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    getRecentActivity(language: string) {
        return new Promise((resolve, reject) => {
            AxiosWebAPI.get(`${process.env.REACT_APP_WebAPI_BaseURL}/investorportal-api/Investor/GetRecentActivity?language=${language}`)
                .then((data) => { 
                    if (data !== null) {
                        return resolve(data.data);
                    } else {
                        return reject(null);
                    }
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    getCashflowChart(data: any):Promise<any> {
        let idInstitution =  data.idInstitution;
        let vehicles = data.vehiclesSelectedChart;
        let query = vehicles.map((vehicle:any) => `idVehicles=${vehicle}`).join('&');
        return new Promise((resolve, reject) => {
            AxiosWebAPI.get(`${process.env.REACT_APP_WebAPI_BaseURL}/investorportal-api/Chart/GetCashflowsChart?IdInstitution=${idInstitution}&${query}`)
            .then((data) => { 
                if (data !== null) {
                    return resolve(data.data);
                } else {
                    return reject(null);
                }
            }).catch((error) => {
                return reject(error);
            });
        });
    },
    getFlowChart(data: any):Promise<any> {
        let query = data.map((vehicle:any) => `IdInvestors=${vehicle}`).join('&');
        return new Promise((resolve, reject) => {
            AxiosWebAPI.get(`${process.env.REACT_APP_WebAPI_BaseURL}/investorportal-api/Chart/GetFlowsChart?${query}`)
            .then((data) => { 
                if (data !== null) {
                    return resolve(data.data);
                } else {
                    return reject(null);
                }
            }).catch((error) => {
                return reject(error);
            });
        });
    },
    getNews(language: string) {
        return new Promise((resolve, reject) => {
            AxiosWebAPI.get(`${process.env.REACT_APP_WebAPI_BaseURL}/investorportal-api/Wordpress/GetPosts?language=${language}`)
                .then((data) => { 
                    if (data !== null) {
                        return resolve(data.data);
                    } else {
                        return reject(null);
                    }
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    getMedia(language: string) {
        return new Promise((resolve, reject) => {
            AxiosWebAPI.get(`${process.env.REACT_APP_WebAPI_BaseURL}/investorportal-api/Investor/GetMedia?Language=${language}&Types=Newsletter&Types=Video`)
                .then((data) => { 
                    if (data !== null) {
                        return resolve(data.data);
                    } else {
                        return reject(null);
                    }
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
    getInstitutions() {
        return new Promise((resolve, reject) => {
            AxiosWebAPI.get(`${process.env.REACT_APP_WebAPI_BaseURL}/investorportal-api/Investor/GetInstitutions`)
                .then((data) => { 
                    if (data !== null) {
                        return resolve(data.data);
                    } else {
                        return reject(null);
                    }
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    },
	showFile(fileUrl: string) {
		return new Promise((resolve, reject) => {
			let container = (process.env.REACT_APP_WebAPI_BaseURL?.includes("-dev") ||  process.env.REACT_APP_WebAPI_BaseURL?.includes("-qa")) ? 'documents-qa' : 'documents';
			AxiosWebAPI.get(`${process.env.REACT_APP_WebAPI_BaseURL}/documentsystem-api/Documents/Show?containerName=${container}&filePath=/Investor Portal${fileUrl}&minutesAvailable=10080`)
				.then((data) => { 
					if (data !== null) {
						return resolve(data.data);
					} else {
						return reject(null);
					}
				})
				.catch((error) => {
					return reject(error);
				});
		});
	}
}