import { Modal } from "antd";
import Chart, {
  ArgumentAxis,
  CommonSeriesSettings,
  Label,
  Series,
} from "devextreme-react/chart";

const ModalTvpi = (props: any) => {
  const { isTvpiModalOpen, onCloseTvpiModal, tvpiChartData } = props;
  return (
    <Modal
      title="TVPI"
      className="modal-tvpi"
      open={isTvpiModalOpen}
      centered
      onCancel={onCloseTvpiModal}
      destroyOnClose
      footer={null}
      width={1300}
    >
      <Chart
        dataSource={tvpiChartData}
        palette={["#a8a6a6", "#dbdbdb"]}
        resolveLabelOverlapping="hide"
        legend={{
          visible: true,
          verticalAlignment: "top",
          horizontalAlignment: "center",
        }}
        height={500}
        animation={false}
        argumentAxis={{
          tick: { visible: false },
        }}
        valueAxis={{
          visible: false,
          maxValueMargin: 0.05,
          grid: {
            visible: false,
          },
          showZero: true,
          tick: { visible: false },
          label: {
            visible: false,
          },
        }}
      >
        <CommonSeriesSettings
          argumentField="quarter"
          type="stackedbar"
          barWidth={150}
        >
          <Label
            visible={true}
            backgroundColor="none"
            font={{ color: "black", size: "12px" }}
            customizeText={(e) => {
              if (e.valueText === "0") return "";
              const formattedValue = e.valueText.replace(".", ",");
              return `${formattedValue}x`;
            }}
          />
        </CommonSeriesSettings>
        <ArgumentAxis>
          <Label
            visible={true}
            font={{ color: "black", size: "12px" }}
          />
        </ArgumentAxis>
        <Series valueField="rpi" name="RPI" />
        <Series valueField="dpi" name="DPI" />
      </Chart>
    </Modal>
  );
};

export default ModalTvpi;
