export const formatNumber = (num: string | number | undefined, decimals = 0): string => {
    if (num || num === 0) {
        let number = num;
        if(typeof(num)=== 'string') {number = num.replaceAll(".", "")}
        const parsedNumber = Number(number);
        return isNaN(parsedNumber) 
        ? 
        parseFloat(parsedNumber.toString()).toLocaleString('es-ES', { minimumFractionDigits: decimals, maximumFractionDigits: decimals  }) 
        : parseFloat(parsedNumber.toString()).toLocaleString('es-ES', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
    } else {
        return String(num);
    }
}

export const formatConcept = (concept: any, decimals: number = 0): string => {
    if (concept === 0) return '-'
    return formatNumber(concept, decimals);
}