import { LogoutOutlined } from "@ant-design/icons";
import "./UnderMaintenance.scss";
import { useParams } from "react-router-dom";

const UnderMaintenance = (props: any) => {
  const { logout } = props;
  const { lang } = useParams();

  let title = lang === "es" ? "Actualmente estamos en mantenimiento" : "We are currently under maintenance";;

  return (
    <div className="under-maintenance">
      <div className="under-maintanance-header">
        <img
          height="30px"
          src="https://qualitasfunds.blob.core.windows.net/assets/image-gallery/reporting/new_logo.png"
          alt="qualitas-funds"
        />
        <button onClick={logout} className="logout-btn">
          Logout <LogoutOutlined />
        </button>
      </div>
      <div className="under-maintanance-body">
        <h2>{title}</h2>
        <img
          height="350px"
          src="https://qualitasfunds.blob.core.windows.net/assets/image-gallery/undermaintenance.png"
          alt="under-maintenance"
        />
        {lang === "es" ? (
          <p>
            <strong>Por favor, inténtelo de nuevo más tarde.</strong> Si su consulta es urgente,  <br/>contáctenos a través de <a href="mailto:administration.fof@qualitasfunds.com">administration.fof@qualitasfunds.com</a>.
          </p>
        ) : (
          <p>
            <strong>Please try again later.</strong> If your inquiry is urgent, <br/>please contact us at <a href="mailto:administration.fof@qualitasfunds.com">administration.fof@qualitasfunds.com</a>.
		 </p>
        )}
      </div>
    </div>
  );
};

export default UnderMaintenance;
