import { useEffect, useRef, useState } from "react";
import ReportsApi from "../Api/api.Reports";
import { notification } from "antd";

const useReporting = (props: any) => {
  const { language, translations } = props;

  const [vehicleCategory, setVecicleCategory] = useState<string>("");
  const [reportType, setReportType] = useState<string>("");
  const [reportData, setReportData] = useState<any>();
  const [hasPermission, setHasPermission] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState<any>(null);

  useEffect(() => {
    const type = new URLSearchParams(window.location.search).get("type");
    setReportType(type || "");


	if(type === "capital-account"){
		const idInvestor = new URLSearchParams(window.location.search).get("investor");
		const date = new URLSearchParams(window.location.search).get("date");
		if(!idInvestor || !date){
			notification.error({
				message: "Error",
				description: "Error generating Capital Account report",
			});
			return;
		}
		const generateCapitalAccount = async (idInvestor: any, date: string, language: string) => {
			setLoading(true);
			setReportData(undefined);
			setHasPermission(false);
			const data = { idInvestor, date, language };
			try {
				await ReportsApi.generateCapitalAccount(data).then((res: any)=>{
					if(res?.success){
						setHasPermission(true)
						if(res.data?.vehicleType){
							setVecicleCategory(res.data?.vehicleType);
						}
						setReportData(res.data);
					}else if(res?.errorMessage){
						notification.error({
							message: "Error",
							description: res.errorMessage,
						});
					}
					setLoading(false)
			})
			} catch (error) {
				notification.error({
					message: "Error",
					description: "Error generating Capital Account report",
				});
			  setLoading(false)
			}
			setLoading(false);
		}
		generateCapitalAccount(idInvestor, date, language);
	}
	/* /Report/?type=pdf&registry=capital-call&document=1 */
	if(type === "pdf"){
		setLoading(true);
		setReportData(undefined);
		setHasPermission(false);

		const registry = new URLSearchParams(window.location.search).get("registry");
		const idRegistry = new URLSearchParams(window.location.search).get("idRegistry");
		const qFundInvest = new URLSearchParams(window.location.search).get("qFundInvest");

		if(!registry || !idRegistry){
			notification.error({
				message: "Error",
				description: "Error getting document",
			});
			setHasPermission(false);
			return;
		}

		const viewPdf = async() => {
			let data = {
				registry: registry,
				idRegistry: idRegistry,
				qFundInvest: qFundInvest
			}
			await ReportsApi.ViewPDF(data).then(res=>{
				if(res && res?.length > 0){
					setPdfUrl(res[0]?.url)	
					setLoading(false)
					setHasPermission(true)
				}else{
					notification.error({
						message: "Error",
						description: "Error getting document",
					});
					setLoading(false)
					setHasPermission(false)
				}
			}).catch((_e: any) => {
				setLoading(false)
			})
		}	
		viewPdf();
	}
  }, [language]);

  const previewRef = useRef<any>(null);

  const downloadReport = () => {
	const iframe = document.getElementById("ifmcontentstoprint") as HTMLIFrameElement;
	const pri = iframe?.contentWindow;
  
	if (pri) {
	  pri.document.open();
	  const styles = Array.from(document.querySelectorAll('link[rel="stylesheet"], style'))
		.map((style) => style.outerHTML)
		.join("");
	  
	  // Adding print-color-adjust: exact; to the body and all elements
	  const customStyles = `
		<style>
		  body, .template-container {
			-webkit-print-color-adjust: exact;
			print-color-adjust: exact;
		  }
		</style>
	  `;
  
	  pri.document.write(`
		<html>
		  <head>
			${styles}
			${customStyles} <!-- Adding custom styles here -->
		  </head>
		  <body>
			${previewRef.current.innerHTML}
		  </body>
		</html>
	  `);
  
	  /* remove elemennnnnnt with class separator */
	  const separator = pri.document.querySelector(".page-separator");
	  if (separator) {
		separator.remove();
	  }
	  pri.document.close();
	  pri.focus();
	  pri.addEventListener("load", () => {
		pri.print();
	  });
	}
  };

  const formatNumber = (
	num: string | number | undefined,
	decimals = 2,
  ): string => {
	if (num === undefined || num === null) return '';
	if (num === '-') return '-';
	if (num === '') return '';
  
	let parsedNumber: number;
	let isNegative = false;
  
	if (typeof num === 'string') {
	  // Verificar si el número es negativo
	  if (num.startsWith('-')) {
		isNegative = true;
		num = num.slice(1); // Quitar el signo negativo para el parsing
	  }
  
	  // Detectar si la cadena usa formato de separador de miles
	  if (/^\d{1,3}(?:\.\d{3})*(?:,\d+)?$/.test(num)) {
		// Formato europeo: "123.456.789,12"
		num = num.replace(/\./g, '').replace(',', '.');
	  } else if (/^\d{1,3}(?:,\d{3})*(?:\.\d+)?$/.test(num)) {
		// Formato estadounidense: "123,456,789.12"
		num = num.replace(/,/g, '');
	  } else {
		// Reemplazo general de comas por puntos para casos simples como "17,97"
		num = num.replace(',', '.');
	  }
	  parsedNumber = Number(num);
	} else if (typeof num === 'number') {
	  parsedNumber = num;
	} else {
	  return String(num);
	}
  
	if (isNaN(parsedNumber)) {
	  return String(num);
	}
  
	if (isNegative) {
	  parsedNumber = -parsedNumber;
	}
  
	// Formatear el número con toLocaleString para obtener el formato correcto
	let formattedNumber = parsedNumber.toLocaleString(language === 'es' ? 'es-ES' : 'en-US', {
	  minimumFractionDigits: decimals,
	  maximumFractionDigits: decimals,
	});
  
	// Agregar manualmente el separador de miles para números menores a 10,000
	if ((parsedNumber >= 1000 && parsedNumber < 10000) || (parsedNumber <= -1000 && parsedNumber > -10000)) {
	  formattedNumber = formattedNumber.replace(/^(-?)(\d{1})(\d{3})/, '$1$2.$3');
	}
  
	return formattedNumber;
  };


  const renderTable = (data: any, tableName?: string, scr?: boolean) => {
    return (
		<table>
        <tbody>
          {data.map((row: any, index: number) => (
			  <tr key={index}>
              {row.map((cell: any, index: number) => {
				let addX = ((row[0] === 'RVPI' || row[0] === 'DPI' || row[0] === 'TVPI') && index !== 0) && 'x';

				if(index === 0){
					let translation = translations[cell];
					if(translation){
						return <td key={index}>{translation}</td>
					}
				}

				if(tableName === 'movementData'){
					if(index === 5 && scr){
						return <td key={index}>{formatNumber(cell, 0)} </td>
					}
					if((index === 5 && !scr) || index === 6){
						return <td key={index}>{formatNumber(cell, 4)} </td>
					}
				}

				if(tableName === 'valuationData' || row[0] === "N. Acciones" || row[0] === "N. Shares"){
					return <td key={index}>{formatNumber(cell, 0)} </td>
				}

				if(tableName === 'sharesData' ){
					if(index === 4){
						return <td key={index}>{formatNumber(cell, 4)} </td>
					}
				}
				
				if( row[0] === "Valor Particip." || row[0] === 'Share value'){
					return <td key={index}>{formatNumber(cell, 4)} </td>
				}

				if(row[0] === "N. Particip." || row[0] === "Valor Acciones" ){
					return <td key={index}>{formatNumber(cell, 4)} </td>
				}
					return <td key={index}>{formatNumber(cell)}{addX}</td>
				})}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return {
    previewRef,
    downloadReport,
    formatNumber,
    renderTable,
    vehicleCategory,
    reportType,
    reportData,
    hasPermission,
    loading,
	pdfUrl
  };
};

export default useReporting;
